export default function CoinLib() {
  return (
    <div
      style={{
        height: "42px",
        backgroundColor: "#FFFFFF",
        overflow: "hidden",
        boxSizing: "border-box",
        border: "1px solid #56667F",
        borderRadius: "0px",
        textAlign: "right",
        lineHeight: "14px",
        blockSize: "62px",
        fontSize: "12px",
        fontFeatureSettings: "normal",
        textSizeAdjust: "100%",
        boxShadow: "inset 0 -20px 0 0 #56667F",
        padding: "0px",
        margin: "0px",
        width: "100%",
      }}
    >
      <div
        style={{
          height: "40px",
          padding: "0px",
          borderRadius: "0",
          margin: "0px",
          width: "100%",
        }}
      >
        <iframe
          src="https://widget.coinlib.io/widget?type=horizontal_v2&theme=light&pref_coin_id=1505&invert_hover="
          width="100%"
          title="coin market widget"
          height=""
          //   border="0"
          style={{ border: "0", borderRadius: "0", margin: "0", padding: "0" }}
        ></iframe>
      </div>
    </div>
  );
}
