export default function News() {
  return (
    <div className="  py-6 bg-red-00 ">
      <h3 className=" font-bold text-[12px] md:text-sm text-center md:text-center text-primary ">
        NEWS AND UPDATES{" "}
      </h3>
      <h1 className=" font-bold capitalize text-sm md:text-2xl text-center md:text-center py-5 ">
        Gain insights on the latest news and information{" "}
      </h1>
      {/* <div className=" m-auto w-[95%] flex-wrap md:flex-row py-4  md:py-8  flex justify-normal md:justify-between gap-4 ">
        <div className="news_date  md:w-[30%] bg-white rounded-lg pt-8 ">
          <div>
            <img className=" px-3 md:px-5 " src="/images/news1.png" alt="" />
          </div>
          <div className="p-3 border-b-2 ">
            <p className="text-primary font-bold text-[12px]  md:text-sm py-3 ">
              Shares of swimming pool makers slide after warning about weakening
              demand{" "}
            </p>
          </div>
          <div className="p-3 py-6 date_button  flex items-center bg-red-00 justify-between ">
            <div className=" flex gap-3 text-[12px] items-center ">
              <img src="/images/calendar.svg" alt="" />{" "}
              <p className=" text-[11px] md:text-xs font-bold ">
                24th May, 2024
              </p>
            </div>
            <button className=" flex items-center text-sm bg-primary text-white px-5 rounded-lg font-bold gap-2 py-2 ">
              Read <BsArrowRight />{" "}
            </button>
          </div>
        </div>
        <div className="news_date  md:w-[30%] bg-white rounded-lg pt-8 ">
          <div>
            <img className=" px-3 md:px-5 " src="/images/news2.png" alt="" />
          </div>
          <div className="p-3 border-b-2 ">
            <p className="text-primary font-bold text-[12px]  md:text-sm py-3 ">
              These tips for investing in mutual funds and ETFs help you take
              the lead
            </p>
          </div>
          <div className="p-3 py-6 date_button  flex items-center bg-red-00 justify-between ">
            <div className=" flex gap-3 text-[12px] items-center ">
              <img src="/images/calendar.svg" alt="" />{" "}
              <p className=" text-[11px] md:text-xs font-bold ">
                24th May, 2024
              </p>
            </div>
            <button className=" flex items-center text-sm bg-primary text-white px-5 rounded-lg font-bold gap-2 py-2 ">
              Read <BsArrowRight />{" "}
            </button>
          </div>
        </div>
        <div className="news_date  md:w-[30%] bg-white rounded-lg pt-8 ">
          <div>
            <img className=" px-3 md:px-5 " src="/images/news3.png" alt="" />
          </div>
          <div className="p-3 border-b-2 ">
            <p className="text-primary font-bold text-[12px]  md:text-sm py-3 ">
              Thinking of buying in a 55+ community?. Wait until winter for the
              best price
            </p>
          </div>
          <div className="p-3 py-6 date_button  flex items-center bg-red-00 justify-between ">
            <div className=" flex gap-3 text-[12px] items-center ">
              <img src="/images/calendar.svg" alt="" />{" "}
              <p className=" text-[11px] md:text-xs font-bold ">
                24th May, 2024
              </p>
            </div>
            <button className=" flex items-center text-sm bg-primary text-white px-5 rounded-lg font-bold gap-2 py-2 ">
              Read <BsArrowRight />{" "}
            </button>
          </div>
        </div>
      </div> */}
    </div>
  );
}
