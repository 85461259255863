import CoinLib from "../../Layout/component/CoinLib";
import LayoutOne from "../../Layout/Layout_One";
import AboutAccess from "./component/About";
import Hero from "./component/Hero";
import Investment from "./component/Investment";
import News from "./component/News";
import Research from "./component/Research";
import AboutService from "./component/Service";
import Strategies from "./component/Strategies";
import Testimonials from "./component/Testimonials";
import { MiniChart } from "react-ts-tradingview-widgets";

export default function Landing() {
  return (
    <LayoutOne>
      <Hero />
      <Research />
      <div className="px-5 md:px-20 ">
        <AboutAccess />
      </div>
      <div className=" block">
        <CoinLib />
      </div>
      <Strategies />
      <div className=" px-1 md:px-20 ">
        <AboutService />
      </div>
      <div className="px-3 md:px-20 ">
        <Investment />
      </div>
      <div className="">
        <div className=" h-[400px] md:h-[500px] ">
          <MiniChart
            colorTheme="dark"
            dateRange="ALL"
            copyrightStyles={{
              link: { color: "black" },
              parent: { color: "black" },
            }}
            width={"100%"}
            height={"100%"}
            symbol="BTCUSDT"
          />
        </div>
      </div>
      <div className="px-0 md:px-0 ">
        <Testimonials />
      </div>
      <div className="px-3 md:px-20 ">
        <News />
      </div>
    </LayoutOne>
  );
}
