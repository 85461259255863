import { Button } from "../../components/Button";
import LayoutOne from "../../Layout/Layout_One";
import { inv_types } from "../../Utils/data";
import { SiStackshare } from "react-icons/si";
import { GiArchiveRegister } from "react-icons/gi";
import { PiHandCoinsBold } from "react-icons/pi";
import { BiArrowToRight } from "react-icons/bi";
import { authConfig } from "../../Utils/constants";

export default function Packages() {
  return (
    <LayoutOne>
      <div
        className=" h-[45vh] md:h-[60vh] bg-center bg-cover text-center bg-primary text-white  "
        style={{ backgroundImage: `url('/images/pack.jpg')` }}
      >
        <div className=" h-[45vh] md:h-[60vh] bg-rgba flex-col flex justify-center items-center ">
          <h1 className=" w-[90%] md:w-[55%] leading-[40px] md:leading-[70px] px- md:px-16 font-bold text-2xl md:text-5xl ">
            Discover Investment Plans with the best Pricing
          </h1>
          <p className=" w-[95%] md:w-[50%] px-3 text-sm md:text-lg pt-5 md:pt-8  ">
            From cautious savers to daring risk-takers, we provide a range of
            investment plans customized to suit your distinct financial DNA.
          </p>
        </div>
      </div>
      <div className="">
        <p className=" px-5 md:px-20 text font-light md:font-normal  text-sm md:text-[16px] py-5 text-center ">
          At {authConfig.appName}, we provide a range of tailored investment
          packages designed to align with your financial goals and investment
          capacity. Each package specifies clear minimum and maximum investment
          amounts, offering flexibility and transparency. Whether you aim to
          start with a modest sum or commit to a larger investment, our options
          are structured to optimize growth and manage risk effectively. Explore
          our diverse packages to find the one that best fits your financial
          strategy and begin your journey towards achieving your investment
          objectives with confidence and expertise.
        </p>
        <div className=" w-[90%] md:w-[80%] m-auto flex flex-col md:flex-row flex-wrap gap-8 py-10 justify-between ">
          {inv_types?.map((item, index) => (
            <div
              key={index}
              className=" w-[90%] mx-auto md:w-[30%] bg-primary rounded-lg shadow-md py-5 pb-8 px-5 "
            >
              <div className=" h-32 ring-1 ring-black/5  rounded-lg bg-white/10 backdrop-blur-md flex justify-center items-center  ">
                <img
                  src={item.picture}
                  className="w-[100px] h-[100px]  "
                  alt=""
                />
              </div>

              <p className="text-center text-white py-4 bg-green-00 font-semibold text-xl ">
                {item.min} - {item.max}
              </p>
              <p className="text-center text-white border-b-2 pb-8 bg-yellow-00 font-semibold text-lg ">
                {item.plan} Plan
              </p>
              <div className=" flex gap-3  w-fit mx-auto m-auto py-3 justify-center md:justify-normal items-center ">
                <div className=" h-2 w-2 rounded-full bg-white "></div>
                <p className=" text-white text-sm font-bold ">
                  Referral Commision Of {item.ref}
                </p>
              </div>
              <div className=" flex gap-3 bg-red-00 w-fit mx-auto m-auto justify-center md:justify-normal items-center ">
                <div className=" h-2 w-2 rounded-full bg-white "></div>
                <p className=" text-white text-sm font-bold ">
                  Weekly ROI Of {item.weekly}
                </p>
              </div>
              <Button className=" mt-6 w-full py-3 text-white border-white border- flex justify-center items-center gap-3 ">
                Get Started <BiArrowToRight />
              </Button>
            </div>
          ))}
        </div>
      </div>
      <div
        className="bg-red-00 h-[80vh] bg-[15%]  md:bg-center bg-cover bg-no-repeat "
        style={{ backgroundImage: `url('/images/packages.jpg')` }}
      >
        <div className=" h-full bg-rgba overflow-y-scroll">
          <div className="flex items-center  py-[20px] w-full  ">
            <div className="w-full flex flex-col justify-center  pt-20  ">
              <h1 className=" text-[20px] md:text-[30px] text-white text-center ">
                EARN 10% FOR YOU, 15% FOR THEM
              </h1>
              <p className={` py-[25px] w-full text-white text-center `}>
                We are here for a reason- to spread the wealth.
              </p>
              <div className=" bg-red-00 w-full px-10 flex flex-wrap flex-col md:flex-row justify-center md:justify-between ">
                <div className=" w-[90%] md:w-[25%] shadow-xl md:shadow-none text-[#d7d7d7] h-[220px] md:h-[200px] p-[10px]  mt-[20px] flex flex-col gap-3 ">
                  <div className="flex w-[20%] text-[30px] items-center justify-center mx-auto ">
                    <SiStackshare />
                  </div>
                  <div className="flex justify-center items-center py-[0px] w-full font-bold ">
                    Share
                  </div>
                  <div
                    className={`flex justify-center items-center w-full text-center  `}
                  >
                    Share your custom link with your friends via social media or
                    email.
                  </div>
                </div>
                <div className="w-[90%] md:w-[25%] shadow-xl md:shadow-none text-[#d7d7d7] h-[220px] md:h-[200px] p-[10px]  mt-[20px] flex flex-col gap-3 ">
                  <div className="flex w-[20%] text-[30px] items-center justify-center mx-auto">
                    <GiArchiveRegister />
                  </div>
                  <div className="flex justify-center items-center py-[0px] w-full font-bold">
                    Register
                  </div>
                  <div
                    className={`flex justify-center items-center w-full text-center  `}
                  >
                    Your friends follow the link to complete a few simple
                    instructions.
                  </div>
                </div>
                <div className="w-[90%] md:w-[25%] shadow-xl md:shadow-none text-[#d7d7d7] h-[220px] md:h-[200px] p-[10px]  mt-[20px] gap-3 flex flex-col">
                  <div className="flex w-[20%] text-[30px] items-center justify-center mx-auto">
                    <PiHandCoinsBold />
                  </div>
                  <div className="flex justify-center items-center pb-[0px] w-full font-bold">
                    Earn
                  </div>
                  <div
                    className={`flex justify-center items-center w-full text-center`}
                  >
                    Once your friend makes a direct deposit, you will earn 10%.
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
    </LayoutOne>
  );
}
