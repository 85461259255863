import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initial";
import { RootState } from "../../index";
import { getUsersUpdates } from "../../service/common";

export const updateSlice = createSlice({
  name: "update",
  initialState,
  reducers: {
    setUpdateActive: (state, { payload }: PayloadAction<boolean>) => {
      state.active = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsersUpdates.fulfilled, (state, { payload }) => {
        state.loading = false;

        if (payload && payload?.data?.id) {
          if (state.id === payload.data.id) {
          }

          if (state.id !== payload.data.id) {
            state.id = payload?.data?.id;
            state.active = true;
            state.data = payload?.data;
          }
        }
      })
      .addCase(getUsersUpdates.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUsersUpdates.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default updateSlice.reducer;
export const SelectUpdate = (state: RootState) => state.update;
export const { setUpdateActive } = updateSlice.actions;
