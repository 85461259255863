import { authConfig } from "../../../Utils/constants";
import { insightData } from "../../../Utils/data";

export default function Insight() {
  return (
    <div
      className="bg-no-repeat bg-cover bg-center px-5 pt-10 md:px-20 "
      style={{ backgroundImage: `url('/images/insight.png')` }}
    >
      <div className=" py-6  bg-red-00 ">
        <h3 className=" font-bold text-[12px] md:text-sm text-center md:text-center text-white ">
          DIGITAL MARKETER/ EMPLOYEE BENEFITS
        </h3>
        <h1 className=" font-normal capitalize text-white text-sm md:text-lg text-center md:text-center py-5 ">
          At {authConfig.appName}, our employees are taken care of with great
          salary, referral bonus,awesome benefits and high-quality training no
          matter what your position is. Check it out for yourself!{" "}
        </h1>
        <div className=" m-auto w-[95%] flex-wrap md:flex-row py-4  md:py-8  flex justify-around md:justify-between gap-4 ">
          {insightData?.map((item, index) => (
            <div key={index} className=" w-[47%] md:w-[30%] mb-5   rounded-lg ">
              <div>
                <img
                  // className="h-[30px] w-[30px]  "
                  className="w-full"
                  src={`/images/ins${index + 1}.png`}
                  alt=""
                />
              </div>
              <div className=" border-[1px] min-h-[150px] bg-[#DFECFF] border-t-0 rounded-b-lg border-[#00000033] px-4 py-1 md:py-3 pb-5 ">
                <p className=" text-center font-bold text-[12px] md:text-lg py-1 md:py-4 ">
                  {item.title}
                </p>
                <p className=" text-center  text-[10px] md:text-sm  ">
                  {item.desc}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
