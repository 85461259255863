import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initial";
import { RootState } from "../../index";
import { getReferral } from "../../service/common";

export const referralSlice = createSlice({
  name: "referral",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReferral.fulfilled, (state, { payload }) => {
        state.list = payload.data.list;
        state.link = payload.data.link;
        state.referred_by = payload.data.referred_by;
        // state.pagination = payload.data.pagination;
        state.loading = false;
      })
      .addCase(getReferral.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReferral.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default referralSlice.reducer;
export const SelectReferral = (state: RootState) => state.referral;
