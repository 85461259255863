import { PiArrowRight } from "react-icons/pi";
import useAuthHooks from "../../Hooks/authHooks";
import { Button } from "../../components/Button";
import { FormInput } from "../../components/Input";
import Header from "../../Layout/component/Header";

export default function ForgotPassword() {
  const { forgot } = useAuthHooks();

  return (
    <div>
      <Header />
      <div className="flex  text-black">
        <div
          className="h-[100vh] hidden md:block bg-[86%] w-[30%]  md:bg-center bg-no-repeat py- px-5 bg-cover relative "
          style={{ backgroundImage: `url('/images/login.png')` }}
        ></div>
        <div className=" relative flex h-[100vh] w-full md:w-[100%] bg-[#EDF4FF] py-20 md:py-0 justify-center md:items-center ">
          <div className=" md:hidden absolute top-5 left-4 "></div>
          <div className=" h-fit bg-red-00 w-[90%] md:w-3/4  ">
            <div className=" flex md:items-center flex-col md:flex-row justify-between pb-12 md:pb-0 ">
              <div className=" md:w-full  md:text-center  md:text-[24px] font-bold py-4">
                Forgot Password
              </div>
            </div>
            <form onSubmit={forgot.handleSubmit}>
              <FormInput
                label="Email"
                className="rounded-lg  bg-[#EDF4FF]"
                name="email"
                error={forgot.errors.email}
                value={forgot.values.email}
                onBlur={forgot.handleBlur}
                onChange={forgot.handleChange}
              />

              <Button
                type="submit"
                className=" bg-primary justify-center gap-3 m-auto w-full md:w-fit flex items-center  text-white px-16 py-3 font-bold "
                loading={forgot.isSubmitting}
              >
                Continue <PiArrowRight />
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
