import * as Yup from "yup";

export const loginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required(),
  password: Yup.string().min(8, "Too Short!").max(50, "Too Long!").required(),
});

export const registerSchema = Yup.object().shape({
  fullname: Yup.string().required("Fullname Required").min(6, "Too Short!"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(50, "Too Long!")
    .required("Password is Required"),
  email: Yup.string().email("Invalid email").required("Invalid Email"),
  gender: Yup.string(),
  phone: Yup.string()
    .min(2, "Too Short!")
    .max(14, "Too Long!")
    .required("Phone Number Required"),
  country: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Country Required"),
});

export const forgotSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required(),
});

export const resetSchema = Yup.object().shape({
  password: Yup.string()
    .required()
    .min(8, "Password must be at least 8 characters"),
  password_confirmation: Yup.string()
    .required()
    .min(8, "Password must be at least 8 characters")
    .oneOf([Yup.ref("password"), "null"], "Passwords must match"),
});

export const editProfileSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email"),
  fullname: Yup.string().min(2, "Too Short!").max(50, "Too Long!"),
  last_name: Yup.string().min(2, "Too Short!").max(50, "Too Long!"),
  phone: Yup.string().min(2, "Too Short!").max(20, "Too Long!"),
});
