import { Provider } from "react-redux";
import BrowserLayout from "./Layout/BrowserLayout";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./store";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserLayout />
          <Toaster
            position={"top-right"}
            toastOptions={{ className: "react-hot-toast" }}
          />
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
