import { Outlet, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { AppDispatch } from "../store";
import toast from "react-hot-toast";
import { SelectAuth } from "../store/slice/auth";
import { authConfig } from "../Utils/constants";
import { routeObj } from "../constants/routes";

export const ProtectedRoute = () => {
  let token = localStorage.getItem(authConfig.storageTokenKeyName);

  return token ? <Outlet /> : <Navigate to={`/login`} />;
};

export const NoAccessRoute = () => {
  let token = localStorage.getItem(authConfig.storageTokenKeyName);
  return token === null ? <Outlet /> : <Navigate to={"/dashboard"} />;
};

export const AdminAccess = () => {
  const { user } = useSelector(SelectAuth);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (user) {
      if (user.type !== "ADMIN") {
        dispatch({ type: "auth/logout" });
        navigate(routeObj.login);
        toast.error("Only Admins Can Access This Page ");
      }
    }
  }, [user]);
  return <Outlet />;
};
