import React from "react";
import DashboardLayout from "../../../Layout/DashBoardLayout";

export default function UsersProfit() {
  return (
    <DashboardLayout>
      <div></div>
    </DashboardLayout>
  );
}
