import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { SelectInvestment } from "../../../store/slice/investment";
import { AppDispatch } from "../../../store";
import { getDeposits } from "../../../store/service/investment";
import DashboardLayout from "../../../Layout/DashBoardLayout";
import { Fmtd_Date, generateTableRandomColor } from "../../../Utils/functions";
import { ScaleLoader } from "react-spinners";
import Pagination from "../../../components/Pagination";

export default function ViewDeposits() {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const { deposit, loading, pagination } = useSelector(SelectInvestment);

  const FetchDeposit = async (params: { page: number } | null) => {
    try {
      await dispatch(getDeposits(params));
    } catch (error) {
      console.log(error);
    }
  };

  const sendRequest = (val: number) => {
    return FetchDeposit({ page: val });
  };

  useEffect(() => {
    FetchDeposit(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <DashboardLayout>
      <div className=" relative  min-w-0 break-words w-full  ">
        <p className=" text-[20px] md:text-[25px] font-[700] ">
          User's Deposits
        </p>
        <div className=" block pt-10 w-full dash_webkit overflow-x-auto ">
          <table className=" bg-red-00 w-full md:w-full ">
            <thead className=" mb- bg-primary text-white ">
              <tr className=" text-left  border-b-2  border-[#d7d7d7] ">
                <th className=" py-5 px-3 tracking-wide whitespace-nowrap  ">
                  Deposit Id
                </th>
                <th className=" py-5 px-3 tracking-wide whitespace-nowrap  ">
                  Amount
                </th>
                <th className=" py-5 px-3 tracking-wide whitespace-nowrap  ">
                  Plan
                </th>
                <th className=" py-5 px-3 tracking-wide whitespace-nowrap  ">
                  Status
                </th>
                <th className=" py-5 px-3 tracking-wide whitespace-nowrap  ">
                  Date Of Deposit
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-[#d7d7d7]">
              {!loading &&
                deposit?.length !== 0 &&
                deposit?.map((deposit) => (
                  <TableRow
                    key={deposit?.id}
                    color={generateTableRandomColor()}
                    deposit_id={deposit?.id}
                    amount={deposit?.amount}
                    plan={deposit?.plan}
                    status={deposit?.status}
                    date={Fmtd_Date(deposit?.created_at)}
                  />
                ))}

              {!loading && deposit.length === 0 && (
                <tr>
                  <td className="py-0 text-center " colSpan={5}>
                    <div className="w-full py-10 font-bold bg-main text-white text-sm md:text-[20px] flex justify-center items-center ">
                      You Have No History Of Deposits Made
                    </div>
                  </td>
                </tr>
              )}

              {loading && (
                <tr>
                  <td className="py-6 text-center " colSpan={5}>
                    <div className="w-full py-1 bg-red-00 flex justify-center items-center ">
                      <ScaleLoader />
                    </div>
                  </td>
                </tr>
              )}

              <tr>
                <td className="pt-6" colSpan={5}>
                  <Pagination
                    currentPage={pagination?.current}
                    totalPages={pagination?.total}
                    sendRequest={sendRequest}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </DashboardLayout>
  );
}

const TableRow = memo(
  ({ color, deposit_id, status, amount, plan, date }: any) => {
    return (
      <tr className={`text-left border-b-2  border-[#d7d7d7]`} key={deposit_id}>
        <td className="py-2 whitespace-nowrap px-3">{deposit_id}</td>
        <td className="py-2 whitespace-nowrap px-3">${amount}</td>
        <td className="py-2 whitespace-nowrap px-3">{plan}</td>
        <td className="py-2 whitespace-nowrap px-3">
          <div
            className={` ${
              status === "pending"
                ? "bg-yellow-100/60 text-yellow-600"
                : status === "declined"
                ? " bg-red-400"
                : "bg-green-500"
            } w-fit px-5 py-2 text-white rounded-full capitalize md:text-[13px] `}
          >
            {status}
          </div>
        </td>
        <td className="py-2 whitespace-nowrap px-3">{date}</td>
      </tr>
    );
  }
);
