import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { ReactNode } from "react";
import { FaTimes } from "react-icons/fa";

type Prop = {
  handleClose: () => void;
  children?: ReactNode;
  modalOpen: boolean;
  hasCancelButton?: boolean;
};

export default function ModalP({
  handleClose,
  modalOpen,
  children,
  hasCancelButton = true,
}: Prop) {
  return (
    <div>
      {/* <button onClick={onOpenModal}>Open modal</button> */}
      <Modal
        styles={{
          modal: { borderRadius: "10px" },
        }}
        open={modalOpen}
        onClose={handleClose}
        closeIcon={
          hasCancelButton ? (
            <FaTimes fill="#111" className=" text-[20px] font-bold " />
          ) : (
            <></>
          )
        }
        center
      >
        {children}
      </Modal>
    </div>
  );
}
