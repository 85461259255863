// ** React Imports
import { useState, useEffect, ReactNode } from "react";

// ** Next Import
import { useLocation } from "react-router-dom";

interface Props {
  children: ReactNode;
}

const WindowWrapper = ({ children }: Props) => {
  // ** State
  const [windowReadyFlag, setWindowReadyFlag] = useState<boolean>(false);

  const pathname = useLocation().pathname;

  useEffect(() => setWindowReadyFlag(true), [pathname]);

  if (windowReadyFlag) {
    return <>{children}</>;
  } else {
    return null;
  }
};

export default WindowWrapper;
