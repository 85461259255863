export type Notification = {
  list: Array<{
    id: string;
    title: string;
    preview: string;
    fullMessage: string;
    time: string;
  }>;
  pagination: {
    current: number;
    total: number;
  };
  loading: boolean;
};

export const initialState: Notification = {
  list: [],
  pagination: { current: 1, total: 1 },
  loading: false,
};
