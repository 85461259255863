import axios from "axios";

export const Cloudinary = async (picture: any) => {
  try {
    if (picture) {
      const formData = new FormData();
      formData.append("file", picture);
      formData.append(
        "upload_preset",
        `${process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET}`
      );
      const request = await axios.post(
        `${process.env.REACT_APP_CLOUDINARY_URL}`,
        formData
      );

      return request?.data?.url;
    }
  } catch (error: any) {
    console.log(error);
  }
};
