import Solve from "./Solve";

export default function AboutService() {
  return (
    <div className=" text-center py-6 bg-red-00 ">
      <h3 className=" font-bold text-[12px] md:text-sm text-center md:text-center text-primary ">
        OUR SERVICES{" "}
      </h3>
      <h1 className=" capitalize font-bold text-sm md:text-2xl text-center md:text-center py-5 ">
        Look no further!, we have all your solutions
      </h1>
      <p className=" text-center text-[12px] md:text-[16px]  m-auto w-[90%] ">
        Whether you're just starting out in the world of finance or aiming to
        optimize a complex portfolio, our services are tailored to meet you at
        your current stage and help you progress. Our team of expert advisors
        uses advanced technology alongside proven strategies to create
        personalized solutions that match your specific goals and risk
        tolerance.
      </p>
      <div className=" md:my-4 flex  ">
        <Solve />
      </div>
    </div>
  );
}
