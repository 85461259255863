import { ReactNode } from "react";
import DashboardHeader from "./component/DashboardHeader";
import DashboardSideBar from "./component/DashboardSideBar";
import { useCheckAuth } from "../Hooks/checkAuth";

export default function DashboardLayout({ children }: { children: ReactNode }) {
  useCheckAuth();

  return (
    <div className="relative font-jetbrain ref_sec">
      <div className=" ref_sec mt-[0px] text-black h-[100vh]  flex bg-white ">
        <div className=" hidden bg-primary md:block w-[320px] ">
          <DashboardSideBar />
        </div>
        <div className=" w-[100%]  h-full overflow-scroll  bg-[#F8F8F8] ">
          <div className=" pb-20 bgff p-0  ">
            <div className="bg-red-00 min-h-10 w-full top-0 z-10">
              <DashboardHeader />
            </div>
            <div className="p-5  relative"> {children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
