import React, { useEffect, useRef } from "react";
import { FcGoogle } from "react-icons/fc";
import { useLocation } from "react-router-dom";

export default function Translate() {
  const pathname = useLocation();
  const refEl = useRef<HTMLDivElement | null>(null);
  function googleTranslateElementInit() {
    try {
      //@ts-ignore
      new google.translate.TranslateElement(
        { pageLanguage: "en" },
        "google_translate_element"
      );
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    googleTranslateElementInit();
  }, [pathname]);

  useEffect(() => {
    if (refEl?.current) {
      try {
        const nodesWithSpecificClassName = refEl.current.querySelector(
          ".skiptranslate.goog-te-gadget"
        );
        if (
          nodesWithSpecificClassName &&
          typeof nodesWithSpecificClassName !== "undefined"
        ) {
          Array.from(nodesWithSpecificClassName?.childNodes!).forEach(function (
            node
          ) {
            if (
              node?.nodeType === Node.TEXT_NODE &&
              node?.textContent === "Powered by "
            ) {
              nodesWithSpecificClassName!.removeChild(node);
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [refEl?.current]);

  return (
    <div className=" bg-[#F7F9FB] overflow-scroll flex justify-end items-center gap-2  ">
      <div ref={refEl} id="google_translate_element"></div>
      <div
        onClick={() => {
          googleTranslateElementInit();
        }}
        className=" flex items-center cursor-pointer gap-0 pr-3  "
      >
        <FcGoogle className=" text-[12px] " />
        <p className="text-[12px] ">Translate</p>
      </div>
    </div>
  );
}
