// ** Toolkit imports
import { AnyAction, combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

// ** Reducers
import auth from "./slice/auth";
import dashboard from "./slice/dashboard";
import notification from "./slice/notification";
import investment from "./slice/investment";
import referral from "./slice/referral";
import coin from "./slice/coin";
import admin from "./slice/admin";
import update from "./slice/update";
import blog from "./slice/blog";
import { authConfig } from "../Utils/constants";

const appReducer = combineReducers({
  auth,
  dashboard,
  notification,
  investment,
  referral,
  coin,
  admin,
  update,
  blog,
});

export type RootState = ReturnType<typeof appReducer>;

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "coin", "update"],
};

const rootReducer: any = (state: RootState, action: AnyAction) => {
  if (action.type === "auth/logout") {
    storage.removeItem("persist:root");
    localStorage.removeItem(authConfig.storageTokenKeyName);
    return appReducer(undefined, { type: "" });
  }

  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);
