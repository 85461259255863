export const initialLogin = {
  password: "",
  email: "",
};

export const initialRegister = {
  fullname: "",
  country: "",
  email: "",
  password: "",
  phone: "",
  referral: "",
};

export const initialReset = {
  password: "",
  token: "",
  password_confirmation: "",
};

export const initialForgot = {
  email: "",
};
