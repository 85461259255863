import { createElement, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// import { dashboardDetails, getCoin } from "../store/service/common";
// import { AppDispatch } from "../store";
import { useDispatch, useSelector } from "react-redux";
// import { SelectDashboard } from "../store/slice/dashboard";
import { GiMoneyStack, GiPayMoney, GiTakeMyMoney } from "react-icons/gi";
import { AiFillProfile } from "react-icons/ai";
import { PuffLoader } from "react-spinners";
// import { getUser } from "../store/service/auth";
import { Today, numberFormatter } from "../../../Utils/functions";
import DashboardLayout from "../../../Layout/DashBoardLayout";
import { AppDispatch } from "../../../store";
import { SelectDashboard } from "../../../store/slice/dashboard";
import { getUser } from "../../../store/service/auth";
import { dashboardDetails, getCoin } from "../../../store/service/common";
import Iframe from "../component/Iframe";

export default function UsersDashboard() {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const { total, loading } = useSelector(SelectDashboard);

  const Info: usersInfo = [
    {
      icon: GiPayMoney,
      title: "Total Deposits",
      data: total.depositTot,
    },
    {
      icon: GiMoneyStack,
      title: "Total Withdrawals",
      data: total.withdrawalTot,
    },
    {
      icon: GiTakeMyMoney,
      title: "Total Earning",
      data: total.profitTot,
    },
    {
      icon: AiFillProfile,
      title: "Total Referrals",
      data: total.referrals,
    },
  ];

  useEffect(() => {
    const init = async () => {
      try {
        await dispatch(getUser());
        await dispatch(dashboardDetails());
        await dispatch(getCoin());
      } catch (error) {
        console.log(error);
      }
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <DashboardLayout>
      <div className="">
        <p className=" text-[20px] font-[700] ">Overview</p>
        <p>{Today}</p>

        <div className="flex card_info flex-col gap-10 md:gap-3 flex-wrap pt-10 md:flex-row justify-between ">
          {Info.map((value, index) => (
            <div
              key={index}
              className=" w-full md:w-[23%] px-5 py-8 rounded-md min-h-[80px] bg-white border-[1px] border-[#00000033]  "
            >
              {loading && (
                <div className="">
                  <PuffLoader size={30} />
                </div>
              )}
              {!loading && (
                <div className="flex gap-5  items-center ">
                  <p className=" text-[50px] svg text-primary ">
                    {createElement(value.icon)}
                  </p>
                  <div className=" dash_webkit flex flex-col  overflow-x-scroll ">
                    <p className="">{value.title}</p>
                    <p className="pt-2 ">
                      {value.title !== "Total Referrals"
                        ? `$${numberFormatter(value?.data)}`
                        : value.data}
                    </p>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        <Iframe />
      </div>
    </DashboardLayout>
  );
}
