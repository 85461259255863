import { useFormik } from "formik";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store";
import { createBlogs, updateBlog } from "../store/service/adminApi";
import { Cloudinary } from "../store/service/cloudinary";
import { useNavigate } from "react-router-dom";
import { routeObj } from "../constants/routes";

const initialBlog = {
  title: "",
  description: "",
  image: "",
};

export const useCreateBlog = (File?: File | null, Blog?: BlogData) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const blog = useFormik({
    initialValues: initialBlog,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (!File) {
          return toast.error("Please select an image");
        }
        setSubmitting(true);
        const image = await Cloudinary(File);
        values.image = image;
        const { message } = await dispatch(createBlogs(values)).unwrap();
        setSubmitting(false);
        navigate(routeObj.all_blogs);
        return toast.success(message);
      } catch (error) {
        toast.error(error as string);
      }
    },
  });

  const editBlogs = useFormik({
    initialValues: { ...Blog },
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        if (File) {
          const image = await Cloudinary(File);
          values.image = image;
        }
        const { message } = await dispatch(updateBlog(values)).unwrap();
        setSubmitting(false);
        return toast.success(message);
      } catch (error) {
        toast.error(error as string);
      }
    },
  });

  return { blog, editBlogs };
};
