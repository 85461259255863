export type DashboardDetailsType = {
  data: {
    id: string;
    btc: string;
    usdt: string;
    xrp: string;
    ethereum: string;
  };
  loading: boolean;
};

export const initialState: DashboardDetailsType = {
  data: {
    id: "",
    ethereum: "",
    btc: "",
    usdt: "",
    xrp: "",
  },
  loading: false,
};
