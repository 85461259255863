import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { SelectAuth } from "../../../store/slice/auth";
import DashboardLayout from "../../../Layout/DashBoardLayout";
import { FormInput, SelectInput } from "../../../components/Input";
import { Button } from "../../../components/Button";
import { useEditProfile } from "../../../Hooks/useAccountHooks";

export default function EditProfile() {
  const { user } = useSelector(SelectAuth);
  const { editProfile } = useEditProfile(user!);
  const navigate = useNavigate();

  return (
    <DashboardLayout>
      <form onSubmit={editProfile.handleSubmit} autoComplete="off">
        <p className=" text-[20px] font-bold ">Edit Profile</p>
        <div className=" md:mt-20 flex gap-10 flex-wrap justify-between ">
          <div className=" py-0 px-3 w-full md:w-[44%] bg ">
            <FormInput
              marginBottom={false}
              borderShown={false}
              name="fullname"
              label="Full Name"
              className=" rounded-lg "
              type="text"
              error={editProfile.errors.fullname!}
              onBlur={editProfile.handleBlur}
              onChange={editProfile.handleChange}
              value={editProfile.values.fullname}
            />
          </div>
          <div className=" py-0 px-3 w-full md:w-[44%] bg ">
            <FormInput
              marginBottom={false}
              borderShown={false}
              name="email"
              className=" rounded-lg "
              type="text"
              label="Email"
              error={editProfile.errors.email!}
              onBlur={editProfile.handleBlur}
              onChange={editProfile.handleChange}
              value={editProfile.values.email}
            />
          </div>
          <div className=" py-0 px-3 w-full md:w-[44%] bg ">
            <FormInput
              marginBottom={false}
              borderShown={false}
              name="phone"
              className=" rounded-lg "
              type="text"
              label="Phone Number"
              error={editProfile.errors.phone!}
              onBlur={editProfile.handleBlur}
              onChange={editProfile.handleChange}
              value={editProfile.values.phone}
            />
          </div>
          <div className=" py-0 px-3 w-full md:w-[44%] bg ">
            <FormInput
              marginBottom={false}
              borderShown={false}
              name="country"
              className=" rounded-lg "
              type="text"
              label="Country"
              error={editProfile.errors.country!}
              onBlur={editProfile.handleBlur}
              onChange={editProfile.handleChange}
              value={editProfile.values.country}
            />
          </div>
          <div className=" py-0 px-3 w-full md:w-[44%] bg ">
            <SelectInput
              marginBottom={false}
              borderShown={false}
              name="crypto"
              className=" outline-none w-full rounded-lg bg-red-00 "
              onChange={editProfile.handleChange}
              onBlur={editProfile.handleBlur}
              value={editProfile.values.crypto}
              label="Crypto Channel"
            >
              <option value="bitcoin">Bitcoin</option>
              <option value="usdt(erc20)">USDT (ERC20)</option>
              <option value="usdt(trc20)">USDT (TRC20)</option>
            </SelectInput>
          </div>
          <div className=" py-0 px-3 w-full md:w-[44%] bg ">
            <FormInput
              marginBottom={false}
              borderShown={false}
              name="address"
              className=" rounded-lg "
              type="text"
              label="Wallet Address"
              error={editProfile.errors.address!}
              onBlur={editProfile.handleBlur}
              onChange={editProfile.handleChange}
              value={editProfile.values.address}
            />
          </div>
        </div>
        <div className=" flex justify-end px-5  py-10 gap-5 items-center ">
          <button
            type="button"
            onClick={() => navigate(-1)}
            className=" border-primary border-2 text-primary py-3 px-10 rounded-lg "
          >
            Back
          </button>
          <div className="">
            <Button
              className="bg-main w-fit text-white py-3 px-10 rounded-lg "
              type="submit"
              loading={editProfile.isSubmitting}
            >
              Save
            </Button>
          </div>
        </div>
      </form>
    </DashboardLayout>
  );
}
