export type Investment = {
  deposit: Array<DepositData>;
  withdrawal: Array<WithdrawalData>;
  pagination: {
    current: number;
    total: number;
  };
  loading: boolean;
};

export const initialState: Investment = {
  deposit: [],
  withdrawal: [],
  pagination: { current: 1, total: 1 },
  loading: false,
};
