import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initial";
import { RootState } from "../../index";
import { getNotifications } from "../../service/common";

export const dashboardSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.fulfilled, (state, { payload }) => {
        state.list = payload.data.list;
        state.pagination = payload.data.pagination;
        state.loading = false;
      })
      .addCase(getNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNotifications.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default dashboardSlice.reducer;
export const SelectNotification = (state: RootState) => state.notification;
