import { memo, useEffect, useState } from "react";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { PulseLoader, ScaleLoader } from "react-spinners";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { AppDispatch } from "../../../store";
import { SelectAdmin } from "../../../store/slice/admin";
import { deleteBlog, getBlogs } from "../../../store/service/adminApi";
import DashboardLayout from "../../../Layout/DashBoardLayout";
import ModalP from "../../../components/ModalP";
import { Fmtd_Date } from "../../../Utils/functions";
import Pagination from "../../../components/Pagination";
import { routeObj } from "../../../constants/routes";

export default function AllBlogs() {
  const dispatch = useDispatch<AppDispatch>();
  const { blog, loading, pagination } = useSelector(SelectAdmin);
  const [isLoading, setIsLoading] = useState(false);
  const FetchData = async (params: { page: number } | null) => {
    try {
      await dispatch(getBlogs(params));
    } catch (error) {
      console.log(error);
    }
  };

  const handleAction = async (params: Action) => {
    try {
      setIsLoading(true);
      const req = await deleteBlog(params);
      toast.success(req.message);
      await FetchData(null);
      setIsLoading(false);
    } catch (error) {
      toast.error("Approval Failed");
      setIsLoading(false);
    }
  };

  const sendRequest = (val: number) => {
    return FetchData({ page: val });
  };

  useEffect(() => {
    FetchData(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <DashboardLayout>
      <ModalP
        hasCancelButton={false}
        modalOpen={isLoading}
        handleClose={() => {}}
      >
        <div className=" px-10 py-4 ">
          <PulseLoader />
        </div>
      </ModalP>
      <div className=" relative  min-w-0 break-words w-full  ">
        <p className=" text-[20px] md:text-[25px] font-[700] ">Blogs</p>
        <div className="dash_webkit block pt-10 w-full overflow-x-auto ">
          <table className=" bg-red-00 w-full md:w-full ">
            <thead className=" mb-7 ">
              <tr className=" text-left  border-b-2  border-[#d7d7d7] ">
                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Blog Id
                </th>
                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Title
                </th>
                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Created
                </th>
                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-[#d7d7d7]">
              {!loading &&
                blog.length !== 0 &&
                blog?.map((blog) => (
                  <TableRow
                    key={blog.id}
                    id={blog.id}
                    title={blog.title}
                    created={Fmtd_Date(blog.created_at)}
                    onClick={(params: Action) => handleAction(params)}
                  />
                ))}

              {!loading && blog.length === 0 && (
                <tr>
                  <td className="py-0 text-center " colSpan={6}>
                    <div className="w-full py-10 font-bold bg-main text-white text-sm md:text-[20px] flex justify-center items-center ">
                      You Don't Have Any Record
                    </div>
                  </td>
                </tr>
              )}

              {loading && (
                <tr>
                  <td className="py-6 text-center " colSpan={6}>
                    <div className="w-full py-1 bg-red-00 flex justify-center items-center ">
                      <ScaleLoader />
                    </div>
                  </td>
                </tr>
              )}

              <tr>
                <td className="pt-6" colSpan={6}>
                  <Pagination
                    currentPage={pagination?.current}
                    totalPages={pagination?.total}
                    sendRequest={sendRequest}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </DashboardLayout>
  );
}

const TableRow = memo(
  ({
    id,
    title,
    created,
    onClick,
  }: {
    id: string;
    title: string;
    created: string;

    onClick: (params: Action) => void;
  }) => {
    return (
      <tr className={`text-left border-b-2  border-[#d7d7d7]`}>
        <td className="py-5 whitespace-nowrap px-3">{id}</td>
        <td className="py-5 whitespace-nowrap px-3">{title}</td>
        <td className="py-5 whitespace-nowrap px-3">{created}</td>
        <td className="py-5 whitespace-nowrap px-3">
          <div className="flex gap-3 items-center ">
            <Link to={`${routeObj.edit_blog}${id}`}>
              <button
                title="Edit Blog"
                className="bg-slate-200 border-2 border-green-500 p-2 rounded-full  "
              >
                <FaEdit />
              </button>
            </Link>
            <button
              onClick={() => onClick({ id, action: "APPROVE" })}
              title="Delete Blog"
              className="bg-slate-200 border-2 border-red-500 p-2 rounded-full"
            >
              <FaTrashAlt fill="red" />
            </button>
          </div>
        </td>
      </tr>
    );
  }
);
