import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initial";
import { RootState } from "../../index";
import { getUsersBlogs } from "../../service/common";

export const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsersBlogs.fulfilled, (state, { payload }) => {
        state.list = payload?.data?.list;
        state.loading = false;
      })
      .addCase(getUsersBlogs.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUsersBlogs.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default blogSlice.reducer;
export const SelectBlog = (state: RootState) => state.blog;
