import { useFormik } from "formik";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { AppDispatch } from "../store";
import { makeDeposits } from "../store/service/investment";

const initialDeposit = {
  amount: "",
  image: "",
  plan: "",
};

const DepositSchema = Yup.object().shape({
  amount: Yup.string().required("Amount is Required"),
});

export const useMakeDeposit = ({ closeModal }: { closeModal: () => void }) => {
  const dispatch = useDispatch<AppDispatch>();

  const deposit = useFormik({
    initialValues: initialDeposit,
    validationSchema: DepositSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        const { message } = await dispatch(makeDeposits(values)).unwrap();
        closeModal();
        setSubmitting(false);
        return toast.success(message);
      } catch (error) {
        closeModal();
        toast.error(error as string);
      }
    },
  });

  return { deposit };
};
