import { ReactNode } from "react";
import Header from "./component/Header";
import Footer from "./component/Footer";
import Translate from "../Pages/Static/component/Translate";
import { useLocation } from "react-router-dom";

export default function LayoutOne({
  children,
  shadow,
}: {
  children: ReactNode;
  shadow?: boolean;
}) {
  const pathname = useLocation().pathname;
  return (
    <div className="  ref_sec">
      {pathname === "/" && <Translate />}
      <div className={` bg  relative z-10  ${shadow && "bg-[#F7F9FB]"}  `}>
        <Header shadow={shadow!} />
      </div>
      <div className=" ref_sec overflow-scroll  mt-[-20px] text-black  ">
        <div className=" w-[100%]  min-h-full ">
          <div className=" pb-[10px] font-jetbrain bg-[#F7F9FB] ">
            {children}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
