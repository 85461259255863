import { ReactNode, createElement, useEffect, useState } from "react";
import classNames from "classnames";

type Props = {
  error?: any;
  name: string;
  className: string;
  type?: string;
  children?: ReactNode;
  label?: string;
  value?: string | number;
  onChange?: (event: any) => void;
  onClick?: (event: any) => void;
  onBlur?: (event: any) => void;
  icon?: any;
  borderShown?: boolean;
  marginBottom?: boolean;
  // suffix?:
};
export const FormInput = ({
  error,
  name,
  type,
  label,
  onBlur,
  onChange,
  onClick,
  value,
  className,
  borderShown = true,
  marginBottom = true,
  icon,
}: Props) => {
  const [labelActive, setLabelClass] = useState<"active" | "">("");

  useEffect(() => {
    let timeout = setTimeout(() => {
      setLabelClass("active");
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div>
      <div
        className={`flex relative flex-col ${
          marginBottom && "mb-10"
        } items-center`}
      >
        <p
          className={`w-full bg-red-00 relative ${
            !labelActive && "top-6 ml-4 "
          } text-neutral-500 text-left text-xs ${labelActive && "-top-1"}`}
        >
          {label}
        </p>
        <p
          className={`text-right absolute -top-[6px] w-full capitalize  text-red-500 text-[12px] `}
        >
          {error}
        </p>
        <div
          className={` flex text-black text-[14px] rounded-lg  w-full px-[0px] md:px-[0px] mt-[0px] ${
            borderShown && "border-2 border-slate-500 "
          }`}
        >
          <input
            className={classNames(
              ` w-full  py-[17px] md:py-[18px] px-5 outline-none ${className} `
            )}
            placeholder={name}
            autoComplete="new-password"
            name={name}
            value={value || ""}
            type={type}
            onBlur={onBlur}
            onChange={onChange}
            // onClick={onClick}
          />
          {icon && (
            <button
              type="button"
              className="bg-inherit px-3  font-bold text-lg "
              onClick={onClick}
            >
              {createElement(icon)}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export const SelectInput = ({
  error,
  name,
  children,
  marginBottom = true,
  onBlur,
  onChange,
  className,
  value,
  borderShown = true,
  label,
}: Props) => {
  const [labelActive, setLabelClass] = useState<"active" | "">("");

  useEffect(() => {
    let timeout = setTimeout(() => {
      setLabelClass("active");
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return (
    <div>
      <div className={`flex flex-col ${marginBottom && "mb-10"} items-center `}>
        <p
          className={`w-full relative ${
            !labelActive && "top-6 ml-4 "
          } text-neutral-500 text-left text-xs ${labelActive && "-top-1"}`}
        >
          {label}
        </p>
        <p
          className={`text-right w-full capitalize  text-red-500 text-[12px] `}
        >
          {error}
        </p>
        <div
          className={` flex text-black text-[14px] rounded-lg  w-full px-[0px] md:px-[0px] mt-[0px] ${
            borderShown && "border-2 border-slate-500 "
          }`}
        >
          <select
            // className=" outline-none text-[14px] py-[17px] md:py-[18px] w-full px-[10px] md:px-[34px] mt-[10px]  "
            className={classNames(
              `   py-[17px] md:py-[18px] px-5 outline-none ${className} `
            )}
            autoComplete="new-password"
            name={name}
            value={value || ""}
            onBlur={onBlur}
            onChange={onChange}
          >
            {children}
          </select>
        </div>
      </div>
    </div>
    // <div>
    //   <p className="text-right mt-[30px] text-red-500  ">{error}</p>
    //   <select
    //     className=" outline-none text-[14px] py-[17px] md:py-[18px] w-full px-[10px] md:px-[34px] mt-[10px] bg-ubmain "
    //     autoComplete="new-password"
    //     name={name}
    //     value={value || ""}
    //     onBlur={onBlur}
    //     onChange={onChange}
    //   >
    //     {children}
    //   </select>
    // </div>
  );
};

export const TextAreaInput = ({
  error,
  name,
  children,
  onBlur,
  onChange,
  value,
  label,
}: Props) => {
  return (
    <div>
      <div className=" flex items-center justify-between mb-[10px]">
        <p>{label}</p>

        <p className="text-right text-red-500  ">{error}</p>
      </div>

      <textarea
        className=" outline-none text-[14px] py-[17px] md:py-[18px] h-[500px] w-full px-[10px] md:px-[34px] mt-[0px] bg-ubmain "
        autoComplete="new-password"
        name={name}
        value={value || ""}
        onBlur={onBlur}
        onChange={onChange}
      >
        {children}
      </textarea>
    </div>
  );
};
