import { FaHome } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { routeObj } from "../../constants/routes";
import { PiArrowLeft } from "react-icons/pi";

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <div className=" h-[100vh] flex justify-center items-center flex-col ">
      <h1 className=" text-primary font-bold text-xl ">404 - Page Not Found</h1>
      <p className="  text-center px-4 font-bold ">
        Sorry, the page you are looking for does not exist.
      </p>
      <div className=" flex gap-10 pt-6 items-center ">
        <Link to={routeObj.landing}>
          <div className=" px-6 py-2 rounded-lg bg-slate-700 text-white font-extrabold bg- w-fit flex items-center gap-3 ">
            <FaHome /> Home
          </div>
        </Link>

        <button
          onClick={() => navigate(-1)}
          className=" px-6 py-2 rounded-lg bg-slate-700 text-white font-extrabold bg- w-fit flex items-center gap-3 "
        >
          <PiArrowLeft /> Back
        </button>
      </div>
    </div>
  );
}
