import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";

export default function Pagination({
  currentPage,
  totalPages,
  sendRequest,
}: {
  currentPage: number;
  totalPages: number;
  sendRequest: (val: number) => void;
}) {
  return (
    <ResponsivePagination
      current={currentPage}
      total={totalPages}
      onPageChange={sendRequest}
    />
  );
}
