import { NavLink, DashboardNavLink } from "./NavLink";
import { useState } from "react";
import Drawer from "../../components/Drawer";
import { AnimatePresence } from "framer-motion";
import { MdOutlineSort } from "react-icons/md";
import { Link } from "react-router-dom";
// import { SelectAuth } from "../../store/app/slice/auth";
import { useSelector } from "react-redux";
import { BiArrowFromLeft, BiArrowFromRight, BiMenu } from "react-icons/bi";
import { FaHamburger } from "react-icons/fa";
import { DashboardNavUrls } from "./routes";
import { routeObj } from "../../constants/routes";
import DashboardSideBar from "./DashboardSideBar";
import { FiLogOut } from "react-icons/fi";
import ModalP from "../../components/ModalP";
import Notification from "./Notification";
import { getInitials } from "../../Utils/functions";
import { SelectAuth } from "../../store/slice/auth";
import { CgProfile } from "react-icons/cg";
import useAuthHooks from "../../Hooks/authHooks";

export default function DashboardHeader() {
  const [isOpen, setIsOpen] = useState(false);
  const [logoutOpen, setLogout] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { Logout } = useAuthHooks();
  const toggleModal = () => setModalOpen(!modalOpen);

  const { user } = useSelector(SelectAuth);

  return (
    <div className=" ">
      <AnimatePresence initial={false} mode="wait" onExitComplete={() => null}>
        <Drawer open={isOpen} setIsOpen={setIsOpen}>
          <div
            className={`w-[100%] bg-primary text-white pt-5 h-full flex flex-col justify-around pb-5 px-0 text-[18px]`}
          >
            <div className=" pl-5  flex gap-5 items-center  h-[80px]  ">
              {user?.image ? (
                <img
                  src={user?.image}
                  className=" h-full rounded-full "
                  alt=""
                />
              ) : (
                <CgProfile className="text-[50px]" />
              )}
              <div>
                <p className=" text-[14px] ">{user?.fullname}</p>
                <p className="text-[14px] ">{user?.email}</p>
              </div>
            </div>
            <DashboardSideBar />
          </div>
        </Drawer>
      </AnimatePresence>

      <ModalP modalOpen={modalOpen} handleClose={() => toggleModal()}>
        <Notification />
      </ModalP>

      <div className=" bg-white py-5 shadow-lg   ">
        <div className="w-[100%] md:w-[100%] px-5 h-full m-auto bg-hite flex justify-between items-center ">
          <div className=" flex justify-between bg-red-00 w-full items-center gap-0 ">
            <Link className=" bg-red-00  " to={routeObj.landing}>
              <div className="font-extrabold md:text-xl w-[50%] md:w-[25%] bg-red-00 text-main">
                <img
                  src="/images/logo.png"
                  alt=""
                  className="  md:w-full md:h-[100%] "
                />
              </div>
            </Link>
            <div className="flex items-center gap-5 md:gap-3 ">
              <img
                onClick={() => toggleModal()}
                src="/images/bell.svg"
                alt=""
                className=" cursor-pointer "
              />
              <div
                onClick={() => setLogout(!logoutOpen)}
                className=" relative p-3 font-bold text-primary  cursor-pointer rounded-full bg-slate-300 "
              >
                <p className=" text-xs uppercase ">
                  {getInitials(user?.fullname ?? "Albert Isreal")}
                </p>
                {logoutOpen && (
                  <button
                    onClick={() => {
                      Logout();
                    }}
                    className="absolute -bottom-12 flex items-center text-xs gap-3 bg-white font-bold rounded-lg shadow-xl right-0 bg-red-00 w-fit px-5 py-2 "
                  >
                    <FiLogOut /> Logout
                  </button>
                )}
              </div>
              <button
                id="menu"
                name="menu"
                className=" py-2 text-primary  md:hidden  "
                onClick={() => setIsOpen(!isOpen)}
              >
                <BiMenu
                  className={` text-primary text-[30px] ${isOpen ? "" : ""}`}
                  // fill="white"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
