import { motion } from "framer-motion";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import { authConfig } from "../../../Utils/constants";

export default function AboutAccess() {
  return (
    <div className=" flex flex-col md:flex-row bg-blue-00 items-center -mt-[50px] md:-mt-[100px] py-10 md:py-20  ">
      <div className=" md:w-[50%] md:p-10 ">
        <h3 className=" font-bold text-center md:text-left text-primary ">
          WHO WE ARE
        </h3>

        <p className=" md:w-[80%] font-light py-5 text-[12px] md:text-[16px] text-center md:text-left  ">
          Incorporated on October 8th, 2018, with the company number{" "}
          <Link
            to={
              "https://find-and-update.company-information.service.gov.uk/company/11609296"
            }
            target="_blank"
            className=" text-blue-700 "
          >
            11609296
          </Link>{" "}
          , {authConfig.appName} has quickly established itself as a pioneer in
          the financial landscape. We excel in crafting tailored investment
          strategies, providing expert advisory services, and offering
          innovative risk management solutions. Our role as fiduciaries compels
          us to diligently safeguard and grow our clients' assets. We are
          committed to fostering a culture of inspiration and excellence within
          our team while actively contributing to the vitality of local
          communities.
        </p>
        <motion.button
          whileHover={{ scale: 1.05 }}
          className=" mt-7 hidden md:flex items-center gap-3  bg-primary py-3 px-5 mb-10 md:mb-0 rounded-lg text-white  font-bold mx-auto md:mx-0  "
        >
          Learn More <BsArrowRight />
        </motion.button>
      </div>
      <div className=" hidden md:block mt-10 md:mt-0 ">
        <img src="/images/about.png" loading="lazy" alt="" />
        <motion.button
          whileHover={{ scale: 1.05 }}
          className=" mt-8 flex md:hidden text-sm items-center gap-3  bg-primary py-3 px-5 mb-10 md:mb-0 rounded-lg text-white font-bold mx-auto md:mx-0  "
        >
          Learn More <BsArrowRight />
        </motion.button>
      </div>
    </div>
  );
}
