export type NotifiersType = {
  id: string;
  data: { description: string; title: string } | null;
  active: boolean;
  loading: boolean;
};

export const initialState: NotifiersType = {
  id: "",
  active: false,
  data: null,
  loading: false,
};
