export default function Investment() {
  return (
    <div className=" py-6 bg-red-00 ">
      <h3 className=" font-bold text-[12px] md:text-sm text-center md:text-center text-primary ">
        INVESTMENTS
      </h3>
      <h1 className=" font-bold capitalize text-sm md:text-2xl text-center md:text-center py-5 ">
        Explore our investment options{" "}
      </h1>
      <div className=" m-auto w-[95%] flex-wrap md:flex-row py-4  md:py-8  flex justify-around md:justify-between gap-4 ">
        <div className=" w-[47%] md:w-[30%] bg-[#F2F8FF] border-[1px] border-[#00000033] rounded-lg px-2 md:px-5 py-10 ">
          <div>
            <img
              className="h-[30px] w-[30px]  "
              src="/images/mutual.svg"
              alt=""
            />
          </div>
          <p className=" font-bold text-[12px] md:text-lg py-4 ">
            MUTUAL FUNDS
          </p>
          <p className="  text-[10px] md:text-sm  ">
            Diversify your portfolio effortlessly with our expertly managed
            mutual funds across various sectors. We handle the research and
            investment decisions, enabling you to grow wealth steadily through
            professional asset management
          </p>
        </div>
        <div className=" w-[47%] md:w-[30%] bg-[#F2F8FF] border-[1px] border-[#00000033] rounded-lg px-3 md:px-5 py-10 ">
          <div>
            <img
              className="h-[30px] w-[30px]  "
              src="/images/estate.svg"
              alt=""
            />
          </div>
          <p className=" font-bold text-[12px] md:text-lg py-4 ">
            REAL ESTATE{" "}
          </p>
          <p className=" text-[10px] md:text-sm  ">
            Invest in our REIT for hassle-free exposure to luxury apartments,
            rental properties, and Airbnbs. We manage everything, allowing you
            to build wealth through passive income in a time-tested asset class
          </p>
        </div>
        <div className=" w-[47%] md:w-[30%] bg-[#F2F8FF] border-[1px] border-[#00000033] rounded-lg px-3 md:px-5 py-10 ">
          <div>
            <img
              className="h-[30px] w-[30px]  "
              src="/images/index.svg"
              alt=""
            />
          </div>
          <p className=" font-bold text-[12px] md:text-lg py-4 ">
            INDEX MARKET{" "}
          </p>
          <p className=" text-[10px] md:text-sm  ">
            We actively trade popular indices like S&P 500 and FTSE 100 on major
            global exchanges. Access leverages market volatility through day
            trading, offering diversified exposure without individual stock
            management
          </p>
        </div>
        <div className=" w-[47%] md:w-[30%] bg-[#F2F8FF] border-[1px] border-[#00000033] rounded-lg px-3 md:px-5 py-10 ">
          <div>
            <img
              className="h-[30px] w-[30px]  "
              src="/images/bitc.svg"
              alt=""
            />
          </div>
          <p className=" font-bold text-[12px] md:text-lg py-4 ">
            CRYPTOCURRENCY{" "}
          </p>
          <p className=" text-[10px] md:text-sm  ">
            Cryptocurrencies offer decentralized, bank-free investing and
            trading. Now mainstream, these volatile digital assets can
            potentially boost portfolio returns
          </p>
        </div>
        <div className=" w-[47%] md:w-[30%] bg-[#F2F8FF] border-[1px] border-[#00000033] rounded-lg px-3 md:px-5 py-10 ">
          <div>
            <img
              className="h-[30px] w-[30px]  "
              src="/images/stock.svg"
              alt=""
            />
          </div>
          <p className=" font-bold text-[12px] md:text-lg py-4 ">
            STOCKS AND BONDS{" "}
          </p>
          <p className=" text-[10px] md:text-sm  ">
            Access a curated selection of stocks and bonds tailored to your risk
            profile and goals. Our expert team navigates market complexities,
            allowing you to capitalize on growth opportunities while balancing
            stability.
          </p>
        </div>{" "}
        <div className=" w-[47%] md:w-[30%] bg-[#F2F8FF] border-[1px] border-[#00000033] rounded-lg px-3 md:px-5 py-10 ">
          <div>
            <img
              className="h-[30px] w-[30px]  "
              src="/images/exchange.svg"
              alt=""
            />
          </div>
          <p className=" font-bold text-[12px] md:text-lg py-4 ">
            EXCHANGE TRADED FUND{" "}
          </p>
          <p className=" text-[10px] md:text-sm  ">
            Invest in our REIT for hassle-free exposure to luxury apartments,
            rental properties, and Airbnbs. We manage everything, allowing you
            to build wealth through passive income in a time-tested asset class
          </p>
        </div>
      </div>
    </div>
  );
}
