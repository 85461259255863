export default function ProfilePicture({
  setSelected,
  CloseModal,
}: {
  setSelected: (val: File) => void;
  CloseModal: () => void;
}) {
  const handleInput = () => {
    document.getElementById("upload-btn")!.click();
  };
  return (
    <div className="  mt-0 text-black w-full  ">
      <p className=" pb-4 text-[20px] font-[700] text-center ">
        Change Profile Picture
      </p>
      <div
        onClick={handleInput}
        className=" py-3 cursor-pointer text-center border-2 border-r-0 border-l-0  "
      >
        Upload Profile Photo
        <input
          type="file"
          onChange={(e) => {
            if (e.target.files) {
              setSelected(e.target.files[0]);
            }
          }}
          hidden
          id="upload-btn"
        />
      </div>
      <div className="flex  ">
        {" "}
        <button className="m-auto my-4 " onClick={CloseModal}>
          Cancel
        </button>
      </div>
    </div>
  );
}
