import { useNavigate } from "react-router";
import DashboardLayout from "../../../Layout/DashBoardLayout";
import { inv_types } from "../../../Utils/data";
import { routeObj } from "../../../constants/routes";

export default function DepositOptions() {
  const navigate = useNavigate();
  return (
    <DashboardLayout>
      <p className=" md:text-[20px] text-primary text-center md:text-left font-[700] ">
        Choose Your Investment Package
      </p>
      <p className=" text-[13px] md:text-[15px] pt-2 pb-5 text-center md:text-left  ">
        Explore our range of six unique investment packages, each designed to
        cater to different financial goals and investment capacities. These
        packages provide clear guidelines on minimum and maximum investment
        amounts, allowing you to select the option that best fits your financial
        strategy. With each package, you benefit from our expert management,
        innovative strategies, and commitment to growing your wealth. Take a
        closer look at the options below and choose the package that aligns with
        your aspirations, setting you on the path to achieving your investment
        goals with Access Wealth.
      </p>
      <div className=" flex flex-wrap justify-between gap-10 ">
        {inv_types?.map((item, index) => (
          <div
            onClick={() => {
              navigate(`${routeObj.deposit_type}${item.plan}`);
            }}
            key={index}
            className=" w-[90%] cursor-pointer mx-auto md:w-[30%] bg-primary rounded-lg shadow-md py-5 pb-8 px-5 "
          >
            <div className=" h-32 ring-1 ring-black/5  rounded-lg bg-white/10 backdrop-blur-md flex justify-center items-center  ">
              <img
                src={item.picture}
                className="w-[100px] h-[100px]  "
                alt=""
              />
            </div>

            <p className="text-center text-white py-4 bg-green-00 font-semibold text-xl ">
              {item.min} - {item.max}
            </p>
            <p className="text-center text-white border-b-2 pb-8 bg-yellow-00 font-semibold text-lg ">
              {item.plan} Plan
            </p>
            <div className=" flex gap-3  w-fit mx-auto m-auto py-3 justify-center md:justify-normal items-center ">
              <div className=" h-2 w-2 rounded-full bg-white "></div>
              <p className=" text-white text-sm font-bold ">
                Referral Commision Of {item.ref}
              </p>
            </div>
            <div className=" flex gap-3 bg-red-00 w-fit mx-auto m-auto justify-center md:justify-normal items-center ">
              <div className=" h-2 w-2 rounded-full bg-white "></div>
              <p className=" text-white text-sm font-bold ">
                Weekly ROI Of {item.weekly}
              </p>
            </div>
            {/* <Button
            
              className=" mt-6 w-full py-3 text-white border-white border- flex justify-center items-center gap-3 "
            >
              Get Started <BiArrowToRight />
            </Button> */}
          </div>
          // <div key={index} className=" w-[90%] m-auto md:w-[30%] bg-red-00 ">
          //   <div className=" h-[200px] flex justify-center items-center bg-main rounded-tr-lg rounded-tl-lg ">
          //     <img className=" h-[60%] " src={inv.picture} alt=" " />
          //   </div>
          //   <div className=" border-2  border-[#728191] ">
          //     <p className=" text-center py-3 border-b-2 border-[#728191] text-[20px] font-[700]  ">
          //       {inv.plan} Plan
          //     </p>
          //     <p className="text-center py-3 border-b-2 border-[#728191] text-[16px]">
          //       Minimum Amount : {inv.min}
          //     </p>
          //     <p className="text-center py-3 border-b-2 border-[#728191] text-[16px]">
          //       Maximum Amount : {inv.max}
          //     </p>
          //     <p className="text-center py-3 border-b-2 border-[#728191] text-[16px]">
          //       Referral : {inv.ref}
          //     </p>
          //     <p className="text-center py-3 border-b-2 border-[#728191] text-[16px]">
          //       Weekly ROI : {inv.weekly}
          //     </p>
          //     <div className=" bg-red-00 flex items-center text-center py-4 border-b-2 border-[#728191] text-[16px] ">
          //       <button
          //         className=" m-auto py-4 px-10 rounded-xl text-white bg-main"
          //         onClick={() => {
          //           navigate(`${routeObj.deposit_type}${inv.plan}`);
          //         }}
          //       >
          //         Deposit
          //       </button>
          //     </div>
          //   </div>
          // </div>
        ))}
      </div>
    </DashboardLayout>
  );
}
