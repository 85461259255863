import React from "react";

export default function Strategies() {
  return (
    <div
      className="bg-no-repeat bg-cover bg-center  bg-rgba "
      style={{ backgroundImage: `url('/images/strategy2.jpg')` }}
    >
      <div className=" bg-rgba pt-20 px-5 md:px-20  ">
        <h3 className=" font-bold text-[12px] md:text-sm text-center md:text-center text-white ">
          INVESTMENT STRATEGIES
        </h3>
        <h1 className=" capitalize font-bold text-sm md:text-2xl text-white text-center md:text-center py-5 ">
          Our core Strategies to success
        </h1>
        <p className=" text-center text-[12px] md:text-[16px] text-white  m-auto md:w-[90%] ">
          These aren't just words on a page. They're the bedrock of every
          decision we make, every strategy we craft, and every relationship we
          build. Our principles guide us in turning your financial aspirations
          into reality.
        </p>
        <div className=" md:my-4 w-[97%] md:w-[90%]  justify-between gap-10 flex-wrap m-auto flex py-10 md:py-20  ">
          <div className=" md:w-[45%] bg-blrgb p-5 rounded-md ">
            <div className=" flex gap-4 md:gap-8  md:justify-normal items-center ">
              <img
                src="/images/strategy.svg"
                className=" h-5 md:h-7 w-5 md:w-7 "
                alt=""
              />
              <p className=" font-semibold text-[13px] md:text-[16px] ">
                Tax Efficiency
              </p>
            </div>
            <p className=" md:px-16 text-[12px] md:text-[16px] py-3 text-left ">
              We boost your investment efficiency through strategic tax
              management. Our approach leverages tax-advantaged accounts and
              timely loss harvesting to help you keep more of what you earn.
            </p>
          </div>
          <div className=" bg-blrgb p-5 rounded-md  md:w-[45%] ">
            <div className=" flex gap-4 md:gap-8  md:justify-normal items-center ">
              <img
                src="/images/strategy.svg"
                className=" h-5 md:h-7 w-5 md:w-7 "
                alt=""
              />
              <p className=" font-semibold text-[13px] md:text-[16px] ">
                Strategic Portfolio Balancing{" "}
              </p>
            </div>
            <p className=" md:px-16 text-[12px] md:text-[16px] py-3 text-left ">
              We boost your investment efficiency through strategic tax
              management. Our approach leverages tax-advantaged accounts and
              timely loss harvesting to help you keep more of what you earn.
            </p>
          </div>
          <div className=" bg-blrgb p-5 rounded-md  md:w-[45%] ">
            <div className=" flex gap-4 md:gap-8  md:justify-normal items-center ">
              <img
                src="/images/strategy.svg"
                className=" h-5 md:h-7 w-5 md:w-7 "
                alt=""
              />
              <p className=" font-semibold text-[13px] md:text-[16px] ">
                Diversification{" "}
              </p>
            </div>
            <p className=" md:px-16 text-[12px] md:text-[16px] py-3 text-left ">
              We safeguard your wealth by strategically distributing investments
              across industries, sectors, and global markets. This time-tested
              approach helps shield your portfolio from isolated market
              downturns
            </p>
          </div>
          <div className=" bg-blrgb p-5 rounded-md  md:w-[45%] ">
            <div className=" flex gap-4 md:gap-8  md:justify-normal items-center ">
              <img
                src="/images/strategy.svg"
                className=" h-5 md:h-7 w-5 md:w-7 "
                alt=""
              />
              <p className=" font-semibold text-[13px] md:text-[16px] ">
                Fiduciary responsibilities{" "}
              </p>
            </div>
            <p className=" md:px-16 text-[12px] md:text-[16px] py-3 text-left ">
              As your dedicated fiduciaries, we place your financial well-being
              at the heart of every decision. Our unwavering commitment ensures
              that your goals drive our actions, free from conflicts of interest
            </p>
          </div>
          <div className="bg-blrgb p-5 rounded-md   md:w-[45%] ">
            <div className=" flex gap-4 md:gap-8  md:justify-normal items-center ">
              <img
                src="/images/strategy.svg"
                className=" h-5 md:h-7 w-5 md:w-7 "
                alt=""
              />
              <p className=" font-semibold text-[13px] md:text-[16px] ">
                Long term focus{" "}
              </p>
            </div>
            <p className=" md:px-16 text-[12px] md:text-[16px] py-3 text-left ">
              We look beyond market fluctuations, focusing on enduring
              strategies that compound your success over time. Our patient,
              forward-thinking approach aims to secure your financial future for
              generations to come
            </p>
          </div>
          <div className=" bg-blrgb p-5 rounded-md  md:w-[45%] ">
            <div className=" flex gap-4 md:gap-8  md:justify-normal items-center ">
              <img
                src="/images/strategy.svg"
                className=" h-5 md:h-7 w-5 md:w-7 "
                alt=""
              />
              <p className=" font-semibold text-[13px] md:text-[16px] ">
                Personalization{" "}
              </p>
            </div>
            <p className=" md:px-16 text-[12px] md:text-[16px] py-3 text-left ">
              Your financial fingerprint is one-of-a-kind. We craft bespoke
              investment solutions that align precisely with your goals, values,
              and life circumstances, adapting as you evolve
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
