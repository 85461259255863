import { useFormik } from "formik";
import { AppDispatch } from "../store";
import { useDispatch } from "react-redux";
import { EditCrypto } from "../store/service/adminApi";
import { getCoin } from "../store/service/common";
import toast from "react-hot-toast";

export const useAddress = (crypto: CryptoCrrncy) => {
  const dispatch = useDispatch<AppDispatch>();

  const address = useFormik({
    initialValues: { ...crypto },
    onSubmit: async (values) => {
      try {
        const { message } = await dispatch(EditCrypto(values)).unwrap();
        await dispatch(getCoin());
        return toast.success(message);
      } catch (error) {
        console.log(error);
      }
    },
  });

  return { address };
};
