import { memo, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ScaleLoader } from "react-spinners";
import { FiActivity } from "react-icons/fi";
import DashboardLayout from "../../../Layout/DashBoardLayout";
import { SelectAdmin } from "../../../store/slice/admin";
import {
  Fmtd_Date,
  replaceSpecialCharsWithSpace,
} from "../../../Utils/functions";
import Pagination from "../../../components/Pagination";
import { getActivities } from "../../../store/service/adminApi";
import { AppDispatch } from "../../../store";

export default function Activity() {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const { activities, loading, pagination } = useSelector(SelectAdmin);

  const FetchData = async (params: { page: number } | null) => {
    try {
      await dispatch(getActivities(params));
    } catch (error) {
      console.log(error);
    }
  };

  const sendRequest = (val: number) => {
    return FetchData({ page: val });
  };

  useEffect(() => {
    FetchData(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <DashboardLayout>
      <div className=" relative  min-w-0 break-words w-full  ">
        <p className=" text-[20px] md:text-[25px] font-[700] ">
          All Activities
        </p>
        <div className=" block pt-10 w-full dash_webkit overflow-x-auto ">
          <table className=" bg-red-00 w-full md:w-full ">
            <tbody className="divide-y divide-[#d7d7d7]">
              {!loading &&
                activities.length !== 0 &&
                activities?.map((activities) => (
                  <TableRow
                    key={activities?.id}
                    title={activities?.title}
                    message={activities?.message}
                    date={Fmtd_Date(activities?.created_at)}
                    name={activities?.display_name}
                  />
                ))}

              {!loading && activities.length === 0 && (
                <tr>
                  <td className="py-0 text-center " colSpan={3}>
                    <div className="w-full py-10 font-bold bg-main text-white text-sm md:text-[20px] flex justify-center items-center ">
                      You Don't Have Any Activity Yet
                    </div>
                  </td>
                </tr>
              )}

              {loading && (
                <tr>
                  <td className="py-6 text-center " colSpan={5}>
                    <div className="w-full py-1 bg-red-00 flex justify-center items-center ">
                      <ScaleLoader />
                    </div>
                  </td>
                </tr>
              )}

              <tr>
                <td className="pt-6" colSpan={5}>
                  <Pagination
                    currentPage={pagination?.current}
                    totalPages={pagination?.total}
                    sendRequest={sendRequest}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </DashboardLayout>
  );
}

const TableRow = memo(({ title, message, name, date }: any) => {
  return (
    <tr className={`text-left border-b-2   border-[#d7d7d7]`}>
      <td>
        <p className=" text-center rounded-full ">
          <FiActivity />
        </p>
      </td>
      <td className="py-5 whitespace-nowrap px-6 md:px-3">
        <p className=" py-3 font-bold  uppercase ">
          {replaceSpecialCharsWithSpace(title)}
        </p>
        <p className=" lowercase ">{replaceSpecialCharsWithSpace(message)}</p>
        <p className=" lowercase text-[12px] ">
          {replaceSpecialCharsWithSpace(name)}
        </p>
      </td>

      <td className="py-5 whitespace-nowrap px-3">{date}</td>
    </tr>
  );
});
