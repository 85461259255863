import { NavUrls } from "./routes";
import { NavLink } from "./NavLink";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { routeObj } from "../../constants/routes";

export default function Header({ shadow }: { shadow?: boolean }) {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const DropIn = {
    hidden: {
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.75,
        ease: "easeInOut",
        // duration: 0.3,
        // type: "spring",
        // damping: 25,
        // stiffness: 500,
      },
    },
    exit: {
      opacity: 0,
    },
  };

  return (
    <div className=" ">
      <div
        className={`h-[60px] py-2 md:h-[80px] ${
          !isOpen && "rounded-br-3xl rounded-bl-3xl"
        } bg-[#F7F9FB] ${shadow && "header relative z-20 "}`}
      >
        <div className="w-[95%]   md:w-[90%] h-full m-auto bg-red-00 flex justify-between items-center ">
          <div className="font-extrabold md:text-xl w-[50%] md:w-[25%] bg-red-00 text-main">
            <img
              onClick={() => navigate("/")}
              src="/images/logo.png"
              alt=""
              className="  md:w-full md:h-[100%] "
            />
          </div>

          <div className="w-fit hidden font-semibold  md:flex gap-10 justify-between items-center ">
            {NavUrls?.map((item) => (
              <NavLink key={item.id} {...item} />
            ))}
          </div>
          <div className="w-fit hidden  bg-green-00 text-sm font-bold   md:flex gap-5 justify-between items-center ">
            <Link to={routeObj.login}>Login</Link>
            <Link to={routeObj.register}>
              <motion.button
                whileHover={{ scale: 1.05 }}
                className=" bg-[#093776] py-3 px-5 rounded-lg text-white font-bold "
              >
                Register
              </motion.button>
            </Link>
          </div>
          {/* <div className="w-fit flex   bg-green-00 text-sm font-bold   md:hidden gap-5 justify-between items-center ">
            <button>Login</button>
            <motion.button
              whileHover={{ scale: 1.05 }}
              className=" bg-[#093776] text- py-2 px-3 rounded-lg text-white font-bold "
            >
              Register
            </motion.button>
          </div> */}
          <button
            id="menu"
            name="menu"
            className=" py-2 px-3 md:hidden mr-4 "
            onClick={() => setIsOpen(!isOpen)}
          >
            <svg
              width="30"
              height="14"
              viewBox="0 0 26 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1H5.5M25 1H11.5M25 13H20.5M1 13H14.5M1 7H25"
                stroke="black"
                strokeWidth="3"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </div>
        <AnimatePresence
          initial={false}
          mode="wait"
          onExitComplete={() => null}
        >
          {isOpen && (
            <motion.div
              variants={DropIn}
              initial="hidden"
              animate="visible"
              exit="exit"
              className={`w-[100%]  top-1 py-4 relative z-50 bg-rgba/100 text-white h-fit flex md:hidden flex-col gap-6 justify-between items-center pb-10 px-10 text-[18px]`}
            >
              {NavUrls?.map((item) => (
                <NavLink key={item.id} {...item} />
              ))}
              <div className="w-[100%] text-center">
                <Link to={routeObj.login}>
                  {" "}
                  <div className="w-fit m-auto text-[15px]">Login</div>
                </Link>
                <Link to={routeObj.register}>
                  <div className=" bg-primary rounded-lg text-sm text-white font-bold my-3 py-3 w-[90%] m-auto ">
                    Register
                  </div>
                </Link>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      {/* <div className=" bg-[#56667F]  md:hidden "></div> */}
    </div>
  );
}
