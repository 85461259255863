import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initial";
import { RootState } from "../../index";
import { dashboardDetails } from "../../service/common";
import { adminDashboardDetails } from "../../service/adminApi";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(dashboardDetails.fulfilled, (state, { payload }) => {
        state.total = payload.data.total;
        state.loading = false;
      })
      .addCase(dashboardDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(dashboardDetails.rejected, (state) => {
        state.loading = false;
      })

      //admin
      .addCase(adminDashboardDetails.fulfilled, (state, { payload }) => {
        state.adminTotal = payload.data.total;
        state.loading = false;
      })
      .addCase(adminDashboardDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(adminDashboardDetails.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default dashboardSlice.reducer;
export const SelectDashboard = (state: RootState) => state.dashboard;
