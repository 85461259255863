import { createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../Http/request";

export const dashboardDetails = createAsyncThunk(
  "dashboard/dashboard",
  async (params, thunkAPI) => {
    try {
      const response = await get("/dashboard");
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getCoin = createAsyncThunk(
  "coin/coin",
  async (params, thunkAPI) => {
    try {
      const response = await get("/coin");
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getNotifications = createAsyncThunk(
  "notification/notification",
  async (params: { page: number } | null, thunkAPI) => {
    try {
      const response = await get(
        `/notification?page=${params ? params.page : 1}`
      );
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getReferral = createAsyncThunk(
  "referral/referral",
  async (params: { page: number } | null, thunkAPI) => {
    try {
      const response = await get(`/referral?page=${params ? params.page : 1}`);
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getUsersUpdates = createAsyncThunk(
  "app/updates/updates",
  async (params, thunkAPI) => {
    try {
      const response = await get(`/updates/view`);
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getUsersBlogs = createAsyncThunk(
  "user/get/blogs",
  async (params, thunkAPI) => {
    try {
      const response = await get(`/blog`);
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const userViewBlog = async (params: string) => {
  try {
    const response = await get(`/blog/view?id=${params}`);
    return response;
  } catch (error: any) {
    throw error;
  }
};
