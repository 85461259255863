//@ts-nocheck

import { useEffect, useRef } from "react";

let tvScriptLoadingPromise: any;

export default function Iframe() {
  const onLoadScriptRef = useRef();

  useEffect(() => {
    onLoadScriptRef.current = createWidget;

    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement("script");
        script.id = "tradingview-widget-loading-script";
        script.src = "https://s3.tradingview.com/tv.js";
        script.type = "text/javascript";
        script.onload = resolve;

        document.head.appendChild(script);
      });
    }

    tvScriptLoadingPromise.then(
      () => onLoadScriptRef.current && onLoadScriptRef.current()
    );

    return () => {
      onLoadScriptRef.current = null;
    };

    function createWidget() {
      if (
        document.getElementById("tradingview_b2664") &&
        "TradingView" in window
      ) {
        new window.TradingView.widget({
          width: "100%",
          height: "100%",
          symbol: "BITSTAMP:BTCUSD",
          interval: "D",
          timezone: "Etc/UTC",
          theme: "light",
          style: "1",
          locale: "en",
          toolbar_bg: "#f1f3f6",
          enable_publishing: false,
          allow_symbol_change: true,
          container_id: "tradingview_b2664",
        });
      }
    }
  }, []);

  return (
    <div className=" pt-10 ">
      <p className=" text-[20px] bg-mai mb-5 py-5 px-3 text-sub_mai font-[700] ">
        Trading View
      </p>
      <div className="h-[500px] md:h-[900px] bg-main overflow-x-scroll ">
        <div className="tradingview-widget-container h-[900px] w-[1200px] md:w-[100%] bg-main ">
          <div id="tradingview_b2664" />
        </div>
      </div>
    </div>
  );
}
