import { memo, useEffect, useState } from "react";
import { AiOutlineCopy } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ScaleLoader } from "react-spinners";
import { AppDispatch } from "../../../store";
import { SelectReferral } from "../../../store/slice/referral";
import { getReferral } from "../../../store/service/common";
import DashboardLayout from "../../../Layout/DashBoardLayout";
import { copyToClipboard, Fmtd_Date } from "../../../Utils/functions";

export default function Referral() {
  const [clipboardState, setClipboardState] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const { list = [], loading, link, referred_by } = useSelector(SelectReferral);

  const FetchReferrals = async (params: { page: number } | null) => {
    try {
      await dispatch(getReferral(params));
    } catch (error) {
      console.log(error);
    }
  };

  // const sendRequest = (val: number) => {
  //   return FetchReferrals({ page: val });
  // };

  useEffect(() => {
    FetchReferrals(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <DashboardLayout>
      <div className="flex flex-col items-center gap-4">
        Your Referral Link:
        {!loading && (
          <div className=" flex text-[12px] md:text-[15px] items-center ">
            <i
              className={` whitespace-nowrap ${
                clipboardState ? "text-[#d9bf84]" : ""
              }`}
            >
              {link ? link : ""}
            </i>
            <AiOutlineCopy
              className=" cursor-pointer "
              onClick={() => {
                copyToClipboard(link ? link : "");
                setClipboardState(true);
              }}
            />
          </div>
        )}
        {loading && (
          <div className="w-full py-1 bg-red-00 flex justify-center items-center ">
            <ScaleLoader />
          </div>
        )}
      </div>

      <div className=" h-[200px] my-10 mt-20 flex flex-col text-white font-bold justify-center items-center bg-main border-4  shadow-2xl ">
        You Were Referred By <br />
        {!loading && <p>{referred_by ? referred_by?.fullname : "Null"}</p>}
        {loading && (
          <div className="w-full py-1 bg-red-00 flex justify-center items-center ">
            <ScaleLoader />
          </div>
        )}
      </div>

      <div className=" block pt-10 w-full dash_webkit overflow-x-auto ">
        <table className=" bg-red-00 w-full md:w-full ">
          <thead className=" mb-7 ">
            <tr className=" text-left  border-b-2  border-[#d7d7d7] ">
              <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                Referral Id
              </th>
              <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                Name
              </th>

              <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                Date Of Referral
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-[#d7d7d7]">
            {!loading &&
              list &&
              list?.length !== 0 &&
              list?.map((referrals) => (
                <TableRow
                  key={referrals?.id}
                  referral_id={referrals?.id}
                  name={referrals?.fullname}
                  date={Fmtd_Date(referrals?.created_at)}
                />
              ))}

            {!loading && list.length === 0 && (
              <tr>
                <td className="py-0 text-center " colSpan={3}>
                  <div className="w-full py-10 font-bold bg-main text-white text-sm md:text-[20px] flex justify-center items-center ">
                    You Haven't Referred Anyone Yet
                  </div>
                </td>
              </tr>
            )}

            {loading && (
              <tr>
                <td className="py-6 text-center " colSpan={5}>
                  <div className="w-full py-1 bg-red-00 flex justify-center items-center ">
                    <ScaleLoader />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </DashboardLayout>
  );
}

const TableRow = memo(({ referral_id, name, date }: any) => {
  return (
    <tr className={`text-left border-b-2 border-[#d7d7d7]`}>
      <td className="py-5 whitespace-nowrap px-3">{referral_id}</td>
      <td className="py-5 whitespace-nowrap px-3">{name}</td>
      <td className="py-5 whitespace-nowrap px-3">{date}</td>
    </tr>
  );
});
