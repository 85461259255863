import "swiper/css";
import { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  EffectFade,
  Autoplay,
  Scrollbar,
  A11y,
  Pagination,
} from "swiper/modules";

import "swiper/css/navigation";
import "swiper/css/effect-fade";
import { Link } from "react-router-dom";
import { routeObj } from "../../../constants/routes";
import { authConfig } from "../../../Utils/constants";

export default function Hero() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="bg-black  hero_view_height relative  md:h-[85vh]">
      <Swiper
        modules={[
          Navigation,
          EffectFade,
          Pagination,
          Scrollbar,
          A11y,
          Autoplay,
        ]}
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        speed={1000}
        spaceBetween={800}
        slidesPerView={1}
        loop
        autoplay={{
          delay: 7000,
          disableOnInteraction: false,
        }}
      >
        <SwiperSlide>
          <div
            className="  hero_view_height relative  md:h-[85vh] bg-center bg-no-repeat bg-cover "
            style={{ backgroundImage: `url('/images/hero1.jpg')` }}
          >
            <div className=" bg-rgba hero_view_height relative  md:h-[85vh] ">
              <div className="  text-center flex justify-center items-center flex-col pt-28 w-full text-white bg-green-00 ">
                <h1 className=" md:w-[50%] uppercase m-auto text-xl md:text-5xl  px-4 text-center font-bold  md:leading-[60px] ">
                  Welcome to {authConfig.appName}
                </h1>
                <p className=" md:w-[60%] pt-6 pb-4 text-[12px] md:text-[15px] px-3 font-medium ">
                  Welcome to {authConfig.appName}, your gateway to a seamless
                  and sophisticated investment journey. Leverage our
                  cutting-edge tools and expert insights to maximize your wealth
                  and secure your financial future.
                </p>
                <div className="flex mt-4 gap-4 bg-red items-center ">
                  <Link to={routeObj.service}>
                    <button className=" border-white px-6 md:px-10 py-3 text-sm md:text-[16px] border-2 rounded-lg font-bold  ">
                      View More
                    </button>
                  </Link>
                  <Link to={routeObj.register}>
                    {" "}
                    <button className="bg-primary text-white font-bold text-sm md:text-[16px] px-6 md:px-10 py-4 border- rounded-lg ">
                      Register Now
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="  hero_view_height relative  md:h-[85vh] bg-center bg-no-repeat bg-cover "
            style={{ backgroundImage: `url('/images/hero3.jpg')` }}
          >
            <div className=" bg-rgba hero_view_height relative  md:h-[85vh] ">
              <div className="  text-center flex justify-center items-center flex-col pt-28 w-full text-white bg-green-00 ">
                <h1 className=" md:w-[50%] uppercase m-auto text-xl md:text-5xl  px-4 text-center font-bold  md:leading-[60px] ">
                  Automated Investment Plans
                </h1>
                <p className=" md:w-[60%] pt-6 pb-4 text-[12px] md:text-[15px] px-3 font-medium ">
                  Take advantage of our automated investment plans designed for
                  maximum efficiency and ease. Simply set your investment
                  preferences, and our sophisticated algorithms will
                  continuously analyze and adjust your portfolio to optimize
                  returns.
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="  hero_view_height relative  md:h-[85vh] bg-center bg-no-repeat bg-cover "
            style={{ backgroundImage: `url('/images/slide3.png')` }}
          >
            <div className="  hero_view_height relative  md:h-[85vh] ">
              <div className="  text-center flex justify-center items-center flex-col pt-28 w-full text-white bg-green-00 ">
                <h1 className=" md:w-[60%] uppercase m-auto text-xl md:text-5xl  px-4 text-center font-bold  md:leading-[60px] ">
                  Cutting-Edge Technology
                </h1>
                <p className=" md:w-[60%] pt-6 pb-4 text-[12px] md:text-[15px] px-3 font-medium ">
                  Our platform provides you with advanced tools and features
                  designed to enhance your investment experience. From real-time
                  data analytics to expert insights, our technology empowers you
                  to make informed decisions and stay ahead of the market.
                  Experience seamless integration, intuitive interfaces, and
                  robust security, all crafted to help you achieve your
                  financial goals with confidence and ease.
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
