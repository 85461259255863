// import { FaWhatsapp } from "react-icons/fa";
// import { NavUrls } from "./routes";
// import { FooterLink } from "./Comp_Layouts/FooterLink";
import { MdLocationOn, MdMessage, MdPhone } from "react-icons/md";
import { Link } from "react-router-dom";
import { NavUrls } from "./routes";
import { FooterLink } from "./FooterLink";
import { FaWhatsapp } from "react-icons/fa";
import { routeObj } from "../../constants/routes";
import { authConfig } from "../../Utils/constants";
// import { routeObj } from "../constants/routes";

export default function Footer() {
  return (
    <div className="relative text-[#5A5B5E] ">
      <Link
        to={`https://wa.link/9qt7bs`}
        className=" block fixed left-7 p-2 z-40 rounded-full bottom-6 text-[30px] bg-green-600 text-white "
      >
        <FaWhatsapp />
      </Link>
      <div className=" px-3 md:px-[50px] py-[50px] w-full bg-hexWhite  flex flex-wrap flex-col md:flex-row justify-between ">
        <div className=" flex w-[90%] m-auto md:w-[20%] bg-fuchsia-00  md:shadow-none min-h-[10px] md:min-h-[300px] md:p-[10px]  flex-col ">
          <p className=" pb-[10px] uppercase text-center md:text-left  text-[15px] font-bold ">
            {authConfig.appName}
          </p>
          <p className=" pb-[20px] text-center md:text-left  text-[13px] md:text-[16px] mt-[10px]">
            Our role as fiduciaries drives us to safeguard and grow our clients'
            assets, all while fostering a culture of inspiration among our team
            and actively contributing to the vitality of local communities.
          </p>
        </div>
        <div className=" md:w-[70%] min-h-[10px] md:min-h-[300px]  bg-neutral-00 flex flex-wrap  ">
          <div className="flex  bg-green-00 w-full text-center md:text-left md:w-[40%]   md:shadow-none  p-[10px] ml-[20px]  flex-col ">
            <p className="pb-[10px] uppercase text-[13px]  md:text-[15px] font-bold">
              Explore more about us
            </p>
            <div className="flex flex-col text-[14px]  md:text-[16px] md:pt-5 ">
              {NavUrls?.map((item) => (
                <FooterLink key={item.id} {...item} />
              ))}
              <Link to={routeObj.terms}>
                <div className="w-fit m-auto md:m-0 bg-red-00 pb-2 flex items-center gap-5">
                  Terms & Conditions
                </div>
              </Link>
            </div>
          </div>
          <div className=" flex flex-col md:w-[35%]   md:shadow-none md:min-h-[300px] p-[10px] ml-[20px] md:mt-[0px]">
            <p className="pb-[10px] text-center md:text-left text-[15px] uppercase font-bold">
              Contact us
            </p>
            <div className="flex flex-col bg-red-00 text-[13px] md:text-[16px] ">
              <div className="mt-[10px] bg-teal-00 flex flex-col md:flex-row justify-center items-center ">
                <MdLocationOn
                  style={{ fontSize: "20px", marginRight: "10px" }}
                />
                <p className=" text-center md:text-left  text-[13px] md:text-sm ">
                  Apartment 1, Trinity Court, Roman Way, Corbridge, England,
                  NE45 5JN
                </p>{" "}
              </div>
              <div className="mt-[10px] flex flex-col md:flex-row text-[13px] md:text-[16px] items-center">
                <MdPhone style={{ fontSize: "20px", marginRight: "10px" }} />
                <p className="text-center md:text-left  "></p>
                <a href="tel:+44 7429 358530" className="">
                  +44 7429 358530
                </a>
                {/* <Link to={} ></Link> */}
              </div>
              <div className="mt-[10px] text-[13px] md:text-[16px] flex flex-col md:flex-row items-center">
                <MdMessage
                  style={{
                    fontSize: "20px",
                    marginRight: "10px",
                  }}
                />
                <a
                  className="text-center md:text-left"
                  href="mailto:info@accesswealthltd.com"
                >
                  info@accesswealthltd.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
