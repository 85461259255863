import React from "react";
import LayoutOne from "../../Layout/Layout_One";
import { MdCheckCircle } from "react-icons/md";
import { authConfig } from "../../Utils/constants";

export default function Terms() {
  return (
    <LayoutOne>
      <div
        // className=" h-[45vh] md:h-[60vh] bg-center bg-cover text-center bg-primary text-white  "
        className=" h-[45vh] md:h-[60vh] bg-center bg-cover text-center bg-primary text-white  "
        style={{ backgroundImage: `url('/images/terms.jpg')` }}
      >
        <div className=" h-[45vh] md:h-[60vh] bg-rgba flex-col flex justify-center items-center ">
          <h1 className=" w-[90%] md:w-[55%] leading-[40px] md:leading-[70px] px- md:px-16 font-bold text-2xl md:text-5xl ">
            Terms & Condition
          </h1>
        </div>
      </div>

      <div className=" text-black w-[90%] m-auto ">
        {/* <p className=" font-bold text-[20px] md:text-[25px] py-6">
          Terms & Conditions
        </p> */}
        <p className=" mt-8 pb-3 text-[13px] md:text-[16px] ">
          Welcome to {authConfig.appName} . We specialize in actively managing
          ETFs and hedge funds to help individuals and entities grow and manage
          their financial assets. By using our services, you agree to adhere to
          the following terms and conditions:
        </p>
        <p className=" pb-3 text-[13px] md:text-[16px] ">
          Our mission is to offer diverse investment opportunities and
          personalized strategies to help you achieve your financial goals.
          These Terms and Conditions govern your use of our website, mobile
          application, and other services we provide (collectively referred to
          as the "Platform").
        </p>
        <p className=" pb-3 text-[13px] md:text-[16px] ">
          Throughout these Terms and Conditions, "we," "us," or "our" refers to
          [{authConfig.appName} ], and "you" or "your" refers to the user
          accessing or using our Platform. By accessing or using the Platform,
          you acknowledge that you have read, understood, and agreed to these
          Terms and Conditions. If you do not agree with any part of these Terms
          and Conditions, please refrain from using our services.
        </p>
        <ul className=" gap-4 text-[13px] md:text-[16px] w-[98%] md:w-[78%] m-auto py-[20px] text-black ">
          <li>
            <div className="flex my-3 items-center ">
              <MdCheckCircle className="mx-3" color="black" />
              <p>
                <b>AWML:</b> Stands for {authConfig.appName}
              </p>
            </div>
          </li>
          <li>
            <div className="flex my-3 items-center ">
              <MdCheckCircle className="mx-3" color="black" />

              <p>
                <b>Platform:</b>Refers to [AWML] website, mobile application,
                and any other services we provide.
              </p>
            </div>
          </li>
          <li>
            <div className="flex my-3 items-center ">
              <MdCheckCircle className="mx-3" color="black" />

              <p>
                <b>Account:</b>A registered user's personal investment account
                on the Platform.
              </p>
            </div>
          </li>
          <li>
            <div className="flex my-3 items-center ">
              <MdCheckCircle className="mx-3" color="black" />

              <p>
                <b>Investment Services:</b>The various investment products,
                strategies, and tools offered by [AWML ] through the Platform.
              </p>
            </div>
          </li>
          <li>
            <div className="flex my-3 items-center ">
              <MdCheckCircle className="mx-3" color="black" />

              <p>
                <b>Eligible User:</b> An individual or entity that meets the
                criteria specified in the Eligibility section below.
              </p>
            </div>
          </li>
          <li>
            <div className="flex my-3 items-center ">
              <MdCheckCircle className="mx-3" color="black" />

              <p>
                <b>User Content:</b>Any information, data, text, images, or
                other materials submitted by users through the Platform.
              </p>
            </div>
          </li>
        </ul>
        <p className=" font-bold text-[20px] md:text-[25px] py-6 ">
          Service Overview
        </p>
        <p className=" pb-3 text-[13px] md:text-[16px] ">
          {authConfig.appName} provides a comprehensive range of investment
          services, including portfolio management, financial planning, and
          advisory services. Our primary objective is to help clients achieve
          their financial goals through tailored investment strategies. We offer
          access to various financial instruments, including ETFs and hedge
          funds, designed to maximize returns while managing risk. Our team of
          experienced professionals leverages market insights, rigorous
          analysis, and advanced technology to deliver personalized investment
          solutions. By engaging our services, you benefit from our expertise,
          resources, and commitment to your financial success.
        </p>

        <p className=" font-bold text-[20px] md:text-[25px] py-6 ">
          Client Obligations{" "}
        </p>
        <p className=" pb-3 text-[13px] md:text-[16px] ">
          As a client, you are required to provide accurate and complete
          information about your financial situation, investment objectives, and
          risk tolerance. This information is crucial for us to develop and
          implement an investment strategy that aligns with your goals. You
          agree to promptly notify us of any significant changes to your
          financial circumstances or investment objectives. You also commit to
          providing any additional information or documentation that we may
          request to comply with regulatory requirements or to better serve your
          needs. Maintaining open and honest communication with us is essential
          for the effective management of your investments.
        </p>
        {/* <p className=" pb-3 text-[13px] md:text-[16px] ">
          You are solely responsible for maintaining the confidentiality of your
          account credentials, including your username and password. Any
          activities that occur under your account are your responsibility, and
          you agree to notify us immediately of any unauthorized use or security
          breaches.
        </p>
        <p className=" pb-3 text-[13px] md:text-[16px] ">
          By registering an account, you consent to receive communications from
          {authConfig.appName}  via email, phone, or other means,
          regarding your account, investments, and important updates related to
          our services.
        </p>
        <p className=" pb-3 text-[13px] md:text-[16px] ">
          {authConfig.appName} , reserves the right to verify your
          identity during the registration process or at any point while using
          the Platform. Failure to provide accurate information or comply with
          identity verification requests may result in the suspension or
          termination of your account.
        </p> */}

        <div>
          <p className=" font-bold text-[20px] md:text-[25px] py-6    ">
            Investment Risks
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            Investing in ETFs and hedge funds involves inherent risks, including
            the potential loss of principal. The value of your investments may
            fluctuate due to market volatility, economic conditions, and other
            factors beyond our control. You acknowledge that past performance is
            not indicative of future results and that there is no guarantee of
            achieving your investment objectives. It is important to understand
            the specific risks associated with each investment vehicle and to be
            prepared for periods of negative performance. We encourage you to
            review the risk disclosures provided and to discuss any concerns
            with your investment advisor.
          </p>
        </div>
        <div>
          <p className=" font-bold text-[20px] md:text-[25px] py-6    ">
            Fees and Expenses
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            {authConfig.appName} , charges fees for certain Investment Services
            provided through the Platform. The specific fees applicable to your
            account will be disclosed during the account registration process
            and are subject to change.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            By using our Investment Services, you agree to pay all applicable
            fees and expenses associated with your account. Fees may include
            account management fees, transaction fees, and other charges as
            outlined in our fee schedule.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            It is your responsibility to review and understand the fee schedule,
            including any changes to fees that may occur over time. We will
            provide reasonable notice of any fee changes, and continued use of
            the Platform after such notice constitutes your acceptance of the
            updated fees.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            Some investment products and services recommended on the Platform
            may also have their own associated fees and expenses. These charges
            will be disclosed in the respective investment product's
            documentation.
          </p>
        </div>
        <div>
          <p className=" font-bold text-[20px] md:text-[25px] py-6    ">
            Confidentiality
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            We are committed to protecting your privacy and the confidentiality
            of your personal and financial information. We adhere to strict
            privacy policies and implement robust security measures to safeguard
            your data. Your information will only be used for the purpose of
            providing investment services and will not be disclosed to third
            parties without your consent, except as required by law. We
            regularly review and update our privacy practices to ensure
            compliance with applicable regulations and to address emerging
            security threats. You have the right to access and review the
            personal information we hold about you and to request corrections if
            necessary.
          </p>
          {/* <p className=" pb-3 text-[13px] md:text-[16px] ">
            <p>
              {" "}
              <b> Market risk:</b> Changes in market conditions, economic
              factors, or geopolitical events may lead to fluctuations in the
              value of investments.
            </p>
            <p>
              {" "}
              <b> Credit risk:</b> Some investments may be subject to credit
              risk, where the issuer of a financial product may default on
              interest or principal payments.
            </p>
            <p>
              {" "}
              <b> Currency risk:</b> Investments denominated in foreign
              currencies are subject to exchange rate fluctuations, which may
              affect returns.
            </p>
            <p>
              {" "}
              <b> Past performance risk:</b> Past performance is not indicative
              of future results, and historical returns should not be the sole
              basis for investment decisions.
            </p>
            <p>
              {" "}
              <b> Past performance risk:</b> Past performance is not indicative
              of future results, and historical returns should not be the sole
              basis for investment decisions.
            </p>
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            It is essential to carefully consider these risks and your risk
            tolerance before making any investment decisions. Access Wealth Investment
M           Management , does not provide personalized investment advice,
            and users are encouraged to seek advice from qualified financial
            advisors.
          </p> */}
        </div>
        <div>
          <p className=" font-bold text-[20px] md:text-[25px] py-6    ">
            Privacy Policy
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            {authConfig.appName} , respects your privacy and is committed to
            protecting your personal information. Our Privacy Policy outlines
            how we collect, use, and safeguard your data.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            <p>
              {" "}
              <b> Personal information:</b> We may collect personal information,
              including but not to your name, contact details, financial
              information, and investment preferences. This information is used
              to provide our services and to comply with legal requirements.
            </p>
            <p>
              {" "}
              <b> Data security:</b> We implement security measures to protect
              your personal information from unauthorized access, loss, or
              disclosure.
            </p>
            <p>
              {" "}
              <b> Data sharing:</b> We may share your information with trusted
              third-party service providers, as necessary to provide our
              services. We do not sell or rent your personal information to
              third parties for marketing purposes.
            </p>
            <p>
              {" "}
              <b> Cookies and tracking technologies:</b> We may use cookies and
              other tracking technologies to enhance your experience on our
              Platform. You can manage your cookie preferences through your
              browser settings.
            </p>
            <p>
              {" "}
              <b> Changes to the Privacy Policy:</b> We may update our Privacy
              Policy from time to time. Any changes will be posted on our
              website, and your continued use of the Platform after such changes
              implies acceptance of the updated Privacy Policy.
            </p>
          </p>
        </div>
        <div>
          <p className=" font-bold text-[20px] md:text-[25px] py-6    ">
            Governing Law
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            These terms and conditions are governed by and construed in
            accordance with the laws of the jurisdiction in which Access Wealth
            operates. Any disputes arising from these terms will be subject to
            the exclusive jurisdiction of the courts in that jurisdiction. By
            using our services, you agree to submit to the personal and
            exclusive jurisdiction of these courts. It is important to
            understand that the legal and regulatory framework governing
            investment services may vary by jurisdiction, and you should
            familiarize yourself with the specific laws and regulations that
            apply to your account.
          </p>
          {/* <p className=" pb-3 text-[13px] md:text-[16px] ">
            {authConfig.appName} , reserves the right to terminate
            your account or suspend your access to the Platform for any reason,
            including suspected fraudulent or unauthorized activities.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            Termination may result in the loss of access to your account and any
            associated investment data. You are responsible for withdrawing your
            funds before account termination if applicable.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            Termination may result in the loss of access to your account and any
            associated investment data. You are responsible for withdrawing your
            funds before account termination if applicable.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            Provisions relating to confidentiality, intellectual property
            rights, limitation of liability, and governing law will survive
            termination and Upon termination, any outstanding fees or expenses
            will become due and payable. .
          </p> */}
        </div>
        <div>
          <p className=" font-bold text-[20px] md:text-[25px] py-6    ">
            Communication and Notices{" "}
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            All communications and notices between Access Wealth and clients
            will be conducted through secure and agreed-upon channels, including
            email, postal mail, and our online client portal. It is your
            responsibility to ensure that we have your current contact
            information and to promptly update us with any changes. We will
            provide regular updates on your account performance, market
            insights, and any important changes to our services or terms. You
            agree to review and respond to communications from us in a timely
            manner. In the event of any urgent or time-sensitive matters, we may
            contact you through additional means as necessary.
          </p>
          {/* <p className=" pb-3 text-[13px] md:text-[16px] ">
            {authConfig.appName} , does not make any warranties or
            representations regarding the accuracy, reliability, or completeness
            of the information provided through the Platform.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            The Platform may contain links to third-party websites or services.
            {authConfig.appName} , is not responsible for the
            content, privacy policies, or practices of these third-party sites.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            {authConfig.appName} , is not liable for any losses,
            damages, or delays caused by events beyond our reasonable control,
            including but not  to, technical failures, acts of nature, or
            cyberattacks
          </p> */}
        </div>
        <div>
          <p className=" font-bold text-[20px] md:text-[25px] py-6    ">
            Termination of Services{" "}
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            Either party may terminate the investment management agreement with
            written notice. Upon termination, we will cease all investment
            activities on your behalf and provide you with a final account
            statement. It is important to note that certain fees and charges may
            still apply upon termination, and these will be outlined in your
            final statement. We will work with you to ensure a smooth transition
            of your investment assets and to address any outstanding issues. You
            are encouraged to discuss any concerns or questions regarding the
            termination process with your investment advisor.
          </p>
          {/* <p className=" pb-3 text-[13px] md:text-[16px] ">
            Any dispute, claim, or controversy arising out of or relating to
            these Terms and Conditions, the Platform, or our Investment Services
            shall be resolved through binding arbitration in accordance with the
            rules of Arbitration Association.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            You and {authConfig.appName}  agree to waive any right
            to participate in a class action or representative proceeding
            concerning any dispute arising from or related to these Terms and
            Conditions or the Platform.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            The decision of the arbitrator(s) shall be final and binding upon
            both parties, and judgment upon the award rendered may be entered in
            any court having jurisdiction.
          </p> */}
        </div>
        <div>
          <p className=" font-bold text-[20px] md:text-[25px] py-6    ">
            Account Security
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            We implement advanced security measures to protect your investment
            account and personal information from unauthorized access and cyber
            threats. These measures include encryption, multi-factor
            authentication, and regular security audits. You are responsible for
            maintaining the confidentiality of your account credentials and for
            promptly reporting any suspected security breaches. We will never
            request sensitive information such as passwords or account details
            via email. If you receive any suspicious communications purporting
            to be from Access Wealth, please notify us immediately. Your
            vigilance and cooperation are essential in ensuring the security of
            your account.
          </p>
          {/* <p className=" pb-3 text-[13px] md:text-[16px] ">
            If any provision of these Terms and Conditions is deemed invalid or
            unenforceable, the remaining provisions shall remain in full force
            and effect.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            Failure by {authConfig.appName}  to enforce any
            provision of these Terms and Conditions shall not be construed as a
            waiver of such provision.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            These Terms and Conditions are not intended to create any
            partnership, joint venture, agency, or employment relationship
            between you and {authConfig.appName} .
          </p> */}
        </div>
        <div>
          <p className=" font-bold text-[20px] md:text-[25px] py-6    ">
            Intellectual Property
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            All content, materials, trademarks, logos, and intellectual property
            displayed or accessible through the Platform are the exclusive
            property of {authConfig.appName} , or its licensors.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            You may not use, copy, modify, distribute, or reproduce any content
            or intellectual property from the Platform without prior written
            consent from {authConfig.appName} .
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            You may use the Platform solely for personal, non-commercial
            purposes, and any other use is strictly prohibited.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            By submitting any User Content through the Platform, you grant Ark
            investment management , a worldwide, non-exclusive, royalty-free,
            and transferable license to use, reproduce, distribute, and display
            such User Content for the purpose of providing our services.
          </p>
        </div>
        <div>
          <p className=" font-bold text-[20px] md:text-[25px] py-6    ">
            Investment Advice and Recommendations{" "}
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            Our investment advice and recommendations are based on thorough
            analysis and are tailored to your specific financial situation and
            goals. While we strive to provide the best possible guidance, it is
            important to understand that all investment decisions carry risks.
            You are encouraged to carefully consider our advice and to seek
            additional opinions if necessary. Our recommendations are not
            guarantees of performance, and you retain full responsibility for
            the final investment decisions. We are committed to providing
            ongoing support and to helping you navigate the complexities of the
            investment landscape.
          </p>
          {/* <p className=" pb-3 text-[13px] md:text-[16px] ">
            {authConfig.appName}  reserves the right to assume
            exclusive defense and control of any matter subject to
            indemnification by you, in which case you agree to cooperate fully
            with {authConfig.appName}  in asserting any available
            defenses.
          </p> */}
        </div>
        <div>
          <p className=" font-bold text-[20px] md:text-[25px] py-6    ">
            Third-Party Services{" "}
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            In certain circumstances, we may engage third-party service
            providers to support our investment management activities. These
            providers may include custodians, brokers, research firms, and
            technology vendors. We conduct due diligence to ensure that these
            third parties meet our standards for quality and security. You
            acknowledge and agree that we may share your information with these
            providers as necessary to deliver our services. We remain
            responsible for the overall management of your account and for the
            performance of any third-party services utilized. Any issues or
            concerns regarding third-party services should be directed to us.
          </p>
          {/* <p className=" pb-3 text-[13px] md:text-[16px] ">
            Any updates or changes to these Terms and Conditions will be posted
            on the Platform, and the date of the last revision will be indicated
            at the top of the document.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            By continuing to use the Platform after any changes are made, you
            agree to be bound by the revised Terms and Conditions.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            It is your responsibility to review these Terms and Conditions
            regularly to stay informed of any updates.
          </p> */}
        </div>
        <div>
          <p className=" font-bold text-[20px] md:text-[25px] py-6    ">
            Severability
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            In the event of such severance, the parties shall negotiate in good
            faith to replace the invalid, unlawful, or unenforceable provision
            with a valid and enforceable provision that achieves the original
            intent of the parties to the extent possible.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            If any provision of these Terms and Conditions is found to be
            invalid, unlawful, or unenforceable by a court of competent
            jurisdiction, such provision shall be deemed severed from these
            Terms and Conditions, and the remaining provisions shall remain
            valid and enforceable to the fullest extent permitted by law.
          </p>
        </div>
        <div>
          <p className=" font-bold text-[20px] md:text-[25px] py-6    ">
            Complaints and Compensation
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            AWML has established procedures in accordance with the FCA Rules for
            consideration of complaints. Details of these procedures are
            available upon request. Should the Investor have a complaint he or
            she should contact Access Wealth Iinancial MMnagement. If Access
            Wealth Iinancial M Management cannot resolve the complaint to the
            satisfaction of the Investor, the Investor may be entitled to refer
            it to the Financial Ombudsman Service. However, the Investor is
            warned that if he or she has been classified as a Professional
            Client, the Financial Ombudsman Service may consider him ineligible
            to complain
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            AWML participates in the Financial Services Compensation Scheme,
            established under FSMA, which provides compensation to an eligible
            Investor in the event of a firm being unable to meet its liabilities
            to clients. Payments under the protected investment business scheme
            are to a maximum of the first £50,000 of the claim. Further
            information is available from Access Wealth Iinancial MMnagement.
            However, the Investor is warned that if he or she is classified as a
            Professional Client, he or she may not be eligible under the
            criteria of the Financial Services Compensation Scheme to
            participate, and is further warned that failure of an Investment to
            deliver a financial return is not a basis for a claim under the
            Financial Services Compensation Scheme in any event
          </p>
        </div>
        <div>
          <p className=" font-bold text-[20px] md:text-[25px] py-6    ">
            Conflicts of Interest
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            AWML may provide custodian, nominee, administrative or other
            services to any person and shall not in any circumstances be
            required to account to the Investor for any profits earned in
            connection therewith. In handling conflicts, Access Wealth Iinancial
            M Management represents and warrants that it will act in compliance
            with FCA Rules.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            AWML is required by FCA Rules to establish, implement and maintain a
            conflicts of interest policy, which sets out the types of actual or
            potential conflicts of interest which may affect Access Wealth
            Iinancial M Management. A copy of this policy will be provided on
            request.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            AWML or any Associate may hold investments in any Investee Company.
          </p>
        </div>
        <div>
          <p className=" font-bold text-[20px] md:text-[25px] py-6    ">
            Transfer of Interests
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            On the death or bankruptcy of the Investor, his or her personal
            representatives or trustee (as the case may be) shall become the
            “Investor” in his or her place and entitled to receive any
            distribution of monies under the Contract as the “Investor”.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            On termination of the Contract, the Investments shall (unless
            otherwise agreed by the Investor) be transferred into the name of
            the Investor (as the underlying beneficial owner of the Investments)
            or to another nominee appointed by the Investor.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            The Investor undertakes not to instruct a transfer in accordance
            with this Condition 8 without the prior written approval of AWML.
          </p>
        </div>
      </div>
    </LayoutOne>
  );
}
