import { we_solve, we_solve2 } from "../../../Utils/data";
import { motion } from "framer-motion";

export default function Solve() {
  const fadeInVariation = {
    initial: {
      opacity: 0,
      y: 30,
    },
    animate: (index: number) => ({
      opacity: 1,
      y: 0,
      // transition: { delay: 0.05 * index },
      transition: {
        delay: 0.05 * index,
        // duration: 0.75,
        ease: "easeInOut",
        duration: 0.3,
        type: "spring",
        damping: 25,
        stiffness: 500,
      },
    }),
  };

  return (
    <div className="">
      <div className="py-6 hidden md:flex  flex-wrap justify-center px gap-5 md:px-20">
        {we_solve?.map((item, index) => (
          <motion.img
            key={`solve-${index}`}
            variants={fadeInVariation}
            initial="initial"
            whileInView="animate"
            viewport={{ once: true }}
            custom={index}
            loading="lazy"
            className=" "
            src={item}
          />
        ))}
      </div>
      <div className="py-6 flex md:hidden flex-wrap justify-center px-0 gap-1 md:px-20">
        {we_solve2?.map((item, index) => (
          <motion.img
            key={`solve-${index}`}
            variants={fadeInVariation}
            initial="initial"
            whileInView="animate"
            viewport={{ once: true }}
            custom={index}
            loading="lazy"
            className="  "
            src={item}
          />
        ))}
      </div>
    </div>
  );
}
