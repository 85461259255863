import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  EffectFade,
  Autoplay,
  Scrollbar,
  A11y,
  Pagination,
} from "swiper/modules";
import { authConfig } from "../../../Utils/constants";
export default function Testimonials() {
  return (
    <div
      className="bg-no-repeat bg-cover bg-center "
      style={{ backgroundImage: `url('/images/strategy2.jpg')` }}
    >
      <div className=" bg-rgba ">
        <div className=" py-6 pt-10  md:py-10 bg-rgba text-white ">
          <h3 className=" font-bold text-[12px] md:text-sm text-center md:text-center text-white ">
            TESTIMONIALS
          </h3>
          <h1 className=" upp font-bold text-sm md:text-2xl text-center md:text-center py-5 ">
            What our investors say about us
          </h1>
          <div className=" w-full pt-[30px] ">
            <Swiper
              modules={[
                Navigation,
                EffectFade,
                Pagination,
                Scrollbar,
                A11y,
                Autoplay,
              ]}
              navigation
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
              speed={1000}
              spaceBetween={800}
              slidesPerView={1}
              loop
              autoplay={{
                delay: 7000,
                disableOnInteraction: false,
              }}
            >
              <SwiperSlide>
                <div className="flex min-h-[0vh]  gap-4 md:min-h-[0vh] justify-center bg-red-00 w-full ">
                  <div className="">
                    <img
                      className=" h-[30px] w-[30px] md:h-[50px] md:w-[50px] "
                      src="/images/comma.svg"
                      alt=""
                    />
                  </div>
                  <div className=" pt-[30px] pb-[10px] md:py-[30px]  text-[12px]  md:text-[15px] w-[80%]  md:w-[60%]">
                    <p className=" md:pt-5 text-left ">
                      I was skeptical at first, but this platform completely
                      transformed my investment strategy. Within 6 months, my
                      portfolio grew by 32%! The real-time market insights
                      helped me make informed decisions I never could have made
                      on my own. I'm now on track to retire 5 years earlier than
                      planned
                    </p>
                    <p className=" pt-[30px] pb-[10px] md:py-[30px]  text-center ">
                      -Jose Mario
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="flex min-h-[0vh]  gap-4 md:min-h-[0vh] justify-center bg-red-00 w-full ">
                  <div className="">
                    <img
                      className=" h-[30px] w-[30px] md:h-[50px] md:w-[50px] "
                      src="/images/comma.svg"
                      alt=""
                    />
                  </div>
                  <div className=" pt-[30px] pb-[10px] md:py-[30px]  text-[12px]  md:text-[15px] w-[80%]  md:w-[60%]">
                    <p className=" md:pt-5 text-left ">
                      Choosing{authConfig.appName} was one of the best decisions
                      I've made. The team's professionalism and dedication are
                      evident in the impressive returns my portfolio has
                      achieved. I highly recommend them to anyone serious about
                      securing their financial future
                    </p>
                    <p className=" pt-[30px] pb-[10px] md:py-[30px]  text-center ">
                      -Anne Marie
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="flex min-h-[0vh]  gap-4 md:min-h-[0vh] justify-center bg-red-00 w-full ">
                  <div className="">
                    <img
                      className=" h-[30px] w-[30px] md:h-[50px] md:w-[50px] "
                      src="/images/comma.svg"
                      alt=""
                    />
                  </div>
                  <div className=" pt-[30px] pb-[10px] md:py-[30px]  text-[12px]  md:text-[15px] w-[80%]  md:w-[60%]">
                    <p className=" md:pt-5 text-left ">
                      Choosing{authConfig.appName} has been one of the most
                      rewarding financial decisions I've ever made. From my
                      initial consultation to the present day, the team's
                      professionalism and dedication have been nothing short of
                      exceptional. The impressive returns my portfolio has
                      achieved under their guidance are a testament to their
                      expertise and commitment to their clients' financial
                      success. I wholeheartedly recommend{authConfig.appName} to
                      anyone who is serious about securing their financial
                      future
                    </p>
                    <p className=" pt-[30px] pb-[10px] md:py-[30px]  text-center ">
                      -Susan Grant
                    </p>
                  </div>
                </div>
              </SwiperSlide>{" "}
              <SwiperSlide>
                <div className="flex min-h-[0vh]  gap-4 md:min-h-[0vh] justify-center bg-red-00 w-full ">
                  <div className="">
                    <img
                      className=" h-[30px] w-[30px] md:h-[50px] md:w-[50px] "
                      src="/images/comma.svg"
                      alt=""
                    />
                  </div>
                  <div className=" pt-[30px] pb-[10px] md:py-[30px]  text-[12px]  md:text-[15px] w-[80%]  md:w-[60%]">
                    <p className=" md:pt-5 text-left ">
                      {authConfig.appName} has consistently exceeded my
                      expectations in every aspect. Their team is not only
                      knowledgeable and experienced, but they also demonstrate a
                      genuine care for their clients' financial well-being. The
                      dedication to providing top-tier service and delivering
                      impressive results is truly commendable. I have full
                      confidence in{authConfig.appName} and wholeheartedly
                      endorse their services to anyone looking for a reliable
                      and trustworthy investment partner.
                    </p>
                    <p className=" pt-[30px] pb-[10px] md:py-[30px]  text-center ">
                      -Daniel Lee
                    </p>
                  </div>
                </div>
              </SwiperSlide>{" "}
              <SwiperSlide>
                <div className="flex min-h-[0vh]  gap-4 md:min-h-[0vh] justify-center bg-red-00 w-full ">
                  <div className="">
                    <img
                      className=" h-[30px] w-[30px] md:h-[50px] md:w-[50px] "
                      src="/images/comma.svg"
                      alt=""
                    />
                  </div>
                  <div className=" pt-[30px] pb-[10px] md:py-[30px]  text-[12px]  md:text-[15px] w-[80%]  md:w-[60%]">
                    <p className=" md:pt-5 text-left ">
                      {authConfig.appName} has transformed the way I approach
                      investing. The team's expertise and tailored approach have
                      resulted in a diversified and profitable portfolio that
                      aligns with my financial goals. Their commitment to their
                      clients' success, combined with their in-depth market
                      knowledge, sets them apart in the industry. I have no
                      hesitation in recommending{authConfig.appName} to anyone
                      seeking a reliable and dedicated investment partner.
                    </p>
                    <p className=" pt-[30px] pb-[10px] md:py-[30px]  text-center ">
                      -James O'Connor
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}
