import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, patch, post } from "../../../Http/request";

export const login = createAsyncThunk(
  "login/login",
  async (params: object, thunkAPI) => {
    try {
      const response = await post("/auth/sign-in", params, {});
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const registeration = createAsyncThunk(
  "register/register",
  async (params: any, thunkAPI) => {
    try {
      const response = await post("/auth/sign-up", params, {});
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "forgot_password/forgot_password",
  async (params: object, thunkAPI) => {
    try {
      const response = await post("/auth/forgot-password", params, {});
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const logout = createAsyncThunk(
  "logout/logout",
  async (params, thunkAPI) => {
    try {
      const response = await post("/auth/sign-out", params, {});
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "reset_password/reset_password",
  async (params: object, thunkAPI) => {
    try {
      const response = await patch("/auth/reset-password", params, {});
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const verifyEmail = createAsyncThunk(
  "verift_email/verify",
  async (params: object, thunkAPI) => {
    try {
      const response = await post("/verify-email", params, {});
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getUser = createAsyncThunk(
  "get_user/user",
  async (params, thunkAPI) => {
    try {
      const response = await get("/user");
      // console.log(res);

      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const EditUser = createAsyncThunk(
  "edit_user/user",
  async (params: object, thunkAPI) => {
    try {
      const response = await patch("/user", params, {});
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const UpdateAccount = createAsyncThunk(
  "edit_user/user-account",
  async (params: object, thunkAPI) => {
    try {
      const response = await post("/user/account", params, {});
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const resendEmail = createAsyncThunk(
  "email/resend_email",
  async (params, thunkAPI) => {
    try {
      const response = await get("/request-email");
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const checkAuth = createAsyncThunk(
  "user/check-auth",
  async (params, thunkAPI) => {
    try {
      const response = await get("/authenticated");
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
