export default function Research() {
  return (
    <div className="bg-green-00 -top-[60px] md:-top-[120px] z-[2] relative">
      <div className="  bg-gray-00 ">
        <div className=" bg-red-00   w-full m-auto  ">
          <div className=" m-auto w-[95%] md:w-[70%] flex-wrap md:flex-row  py-5 flex justify-around md:justify-between gap-4 ">
            <div className=" w-[47%] md:w-[30%] bg-hexWhite shadow-xl rounded-lg px-2 md:px-5 py-10 ">
              <div>
                <img
                  className="h-[30px] w-[30px]  "
                  src="/images/docs.svg"
                  alt=""
                />
              </div>
              <p className=" font-bold text-sm md:text-lg py-4 ">
                Research-Driven Investment Insights
              </p>
              <p className="  text-[10px] md:text-sm md:w-[80%] ">
                We conduct rigorous analysis across sectors and asset classes to
                uncover prime investment opportunities for our clients
              </p>
            </div>
            <div className=" w-[47%] hidden md:block md:w-[30%] bg-primary text-white shadow-xl rounded-lg px-3 md:px-5 py-10 ">
              <div>
                <img
                  className="h-[30px] w-[30px]  "
                  src="/images/case.svg"
                  alt=""
                />
              </div>
              <p className=" font-bold text-sm md:text-lg py-4 ">
                Regular portfolio <br /> Reviews
              </p>
              <p className=" text-[10px] md:text-sm md:w-[80%] ">
                We continually review and adjust your investments to keep pace
                with market shifts and your evolving financial goals
              </p>
            </div>
            <div className=" w-[47%] md:w-[30%] bg-hexWhite shadow-xl rounded-lg px-3 md:px-5 py-10 ">
              <div>
                <img
                  className="h-[30px] w-[30px]  "
                  src="/images/target.svg"
                  alt=""
                />
              </div>
              <p className=" font-bold text-sm md:text-lg py-4 ">
                Tailored Goals, Measured Risks{" "}
              </p>
              <p className=" text-[10px] md:text-sm md:w-[80%] ">
                We start by defining your financial objectives, whether growth,
                income, or preservation, to craft a personalized investment
                strategy.
              </p>
            </div>
            <div className=" w-[47%] block md:hidden md:w-[30%] bg-primary text-white shadow-xl rounded-lg px-3 md:px-5  py-10 ">
              <div>
                <img
                  className="h-[30px] w-[30px]  "
                  src="/images/case.svg"
                  alt=""
                />
              </div>
              <p className=" font-bold text-sm md:text-lg py-4 ">
                Regular portfolio <br /> Reviews
              </p>
              <p className=" text-[10px] md:text-sm md:w-[80%] ">
                We continually review and adjust your investments to keep pace
                with market shifts and your evolving financial goals
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
