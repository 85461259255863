export type Referral = {
  list: Array<{
    created_at: string;
    fullname: string;
    id: string;
  }>;
  link: string;
  referred_by: { id: string; created_at: string; fullname: string } | null;
  pagination: {
    current: number;
    total: number;
  };
  loading: boolean;
};

export const initialState: Referral = {
  list: [],
  link: "",
  referred_by: null,
  pagination: { current: 1, total: 1 },
  loading: false,
};
