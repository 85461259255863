// import { EaseInMotion } from "./Motion/EaseIn";

export const AccordionIT = ({
  title,
  desc,
  isActive,
  setSelected,
  sectionIndex,
}: {
  title: string;
  desc: string;
  isActive: boolean;
  setSelected: (value: number | null) => void;
  sectionIndex: number;
}) => {
  const toggleSection = () => {
    const nextIndex = isActive ? null : sectionIndex;
    setSelected(nextIndex);
  };

  return (
    <div className=" bg-red-00 my-4 ">
      <div
        className="py-[20px]  cursor-pointer flex justify-between rounded-lg  bg-blrgb  border-primary mt-0 px-10 "
        onClick={toggleSection}
      >
        <p className="text-[12px] md:text-[16px] "> {title}</p>{" "}
        <span>{isActive ? "-" : "+"}</span>{" "}
      </div>
      {isActive && (
        <div className="min-h-[100px] shadow-lg text-[12px] md:text-[16px] py-5 px-10">
          <div dangerouslySetInnerHTML={{ __html: desc }}></div>
        </div>
      )}
    </div>
  );
};
