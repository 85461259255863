import React, { useState, useEffect } from "react";
import moment from "moment";

/**
 * Custom hook that creates a countdown timer based on a target date.
 * @param initialTargetDate The initial target date for the countdown.
 * @returns An object containing the remaining time, a function to reset the countdown, and a flag indicating if the countdown has ended.
 */
const useCountdown = (initialTargetDate: any) => {
  // State variables
  const [targetDate, setTargetDate] = useState(moment(initialTargetDate));
  const [remainingTime, setRemainingTime] = useState(
    calculateTimeRemaining(targetDate)
  );
  const [hasEnded, setHasEnded] = useState(false);
  const [hasDays, setHasDays] = useState(false);

  useEffect(() => {
    // Update the remaining time every second
    const intervalId = setInterval(() => {
      const newRemainingTime = calculateTimeRemaining(targetDate);
      setRemainingTime(newRemainingTime);

      // Check if the countdown has ended
      if (
        newRemainingTime.days <= 0 &&
        newRemainingTime.hours <= 0 &&
        newRemainingTime.minutes <= 0 &&
        newRemainingTime.seconds <= 0
      ) {
        setHasEnded(true);
        clearInterval(intervalId); // Stop the interval when the countdown has ended
      }

      if (newRemainingTime.days > 0) {
        setHasDays(true);
      }
    }, 1000);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, [targetDate]);

  /**
   * Resets the countdown with a new target date.
   * @param newTargetDate The new target date for the countdown.
   */
  const resetCountdown = (newTargetDate: any) => {
    setTargetDate(moment(newTargetDate));
    setHasEnded(false);
  };

  return {
    days: remainingTime.days,
    hours: remainingTime.hours,
    minutes: remainingTime.minutes,
    seconds: remainingTime.seconds,
    resetCountdown,
    hasEnded,
    hasDays,
  };
};

/**
 * Calculates the time remaining until a target date.
 *
 * @param targetDate - The target date to calculate the time remaining for.
 * @returns An object containing the number of days, hours, minutes, and seconds remaining.
 */
const calculateTimeRemaining = (targetDate: any) => {
  // Get the current date and time
  const now = moment();

  // Calculate the duration between the current date and the target date
  const duration = moment.duration(targetDate.diff(now));

  // Return an object with the number of days, hours, minutes, and seconds remaining
  return {
    days: duration.days(),
    hours: duration.hours(),
    minutes: duration.minutes(),
    seconds: duration.seconds(),
  };
};

export default useCountdown;
