import { useEffect, useRef, useState } from "react";
import { MdEdit, MdLocationOn } from "react-icons/md";
import { AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { ScaleLoader } from "react-spinners";
import { AppDispatch } from "../../../store";
import { SelectAuth } from "../../../store/slice/auth";
import { EditUser, getUser } from "../../../store/service/auth";
import DashboardLayout from "../../../Layout/DashBoardLayout";
import ModalP from "../../../components/ModalP";
import { routeObj } from "../../../constants/routes";
import ChangeAcc from "../component/ChangeAcc";
import ProfilePicture from "../component/ProfilePicture";
import { Cloudinary } from "../../../store/service/cloudinary";
import SwitchButton from "../../../components/SwitchButton";

export default function Profile() {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const OpenModal = () => setModalOpen(true);
  const CloseModal = () => setModalOpen(false);
  const OpenModal2 = () => setModalOpen2(true);
  const CloseModal2 = () => setModalOpen2(false);
  const [select, setSelected] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const refd = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useSelector(SelectAuth);

  const FetchUser = async () => {
    try {
      await dispatch(getUser());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const uploaddd = async () => {
      try {
        if (select) {
          setLoading(true);
          const image = await Cloudinary(select);
          await dispatch(EditUser({ image }));
          FetchUser();
          CloseModal();
          setLoading(false);
          return toast.success("Profile Updated successfully");
        }
      } catch (error) {
        console.log(error);
      }
    };
    uploaddd();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [select]);

  return (
    <DashboardLayout>
      {modalOpen && (
        <AnimatePresence
          initial={false}
          mode="wait"
          onExitComplete={() => null}
        >
          <ModalP
            modalOpen={modalOpen}
            hasCancelButton={false}
            handleClose={CloseModal}
          >
            {loading && <ScaleLoader />}
            {!loading && (
              <ProfilePicture
                setSelected={setSelected}
                CloseModal={CloseModal}
              />
            )}
          </ModalP>
        </AnimatePresence>
      )}
      <div className="relative h-[120px] md:h-[200px] rounded-lg bg-primary  flex justify-center items-center ">
        <div className="absolute -bottom-10 ">
          <div
            className=" relative h-[100px] md:h-[120px] flex rounded-full border-4 w-[100px] md:w-[120px] bg-black shadow-2xl bg-no-repeat bg-center bg-cover "
            style={{
              backgroundImage: `url(${user?.image ?? "/images/avatar.jpeg"})`,
            }}
          >
            <div className="absolute -bottom-2 md:bottom-2 bg-white rounded-full p-3 right-0 -md:right-3 ">
              <MdEdit onClick={OpenModal} />
            </div>
          </div>
        </div>
      </div>

      <div className=" mt-10 px-4 md:px-12  ">
        {/* <p className=" md:text-[25px] font-[700] ">{user?.fullname}</p> */}
      </div>
      <div className=" mt-0 px-4 md:px-12  flex justify-between items-center ">
        <div className=" flex items-center gap-4 ">
          {/* <MdLocationOn /> {user?.country} */}
        </div>
        <Link to={routeObj.edit_profile}>
          <div className="  bg-sub_main text-white rounded-full p-3 right-0 ">
            <MdEdit />
          </div>
        </Link>
      </div>
      <div className=" mt-10 ">
        <div className="h-[100px] flex flex-col md:flex-row justify-between md:items-center px-3 md:px-7 bg-red-00 ">
          <p className=" font-bold ">Email </p>
          <div className=" w-[100%] md:w-[60%] px-8 py-4 md:py-8 bg-white ">
            {user?.email}
          </div>
        </div>
      </div>
      <div className=" mt-10 ">
        <div className="h-[100px] flex flex-col md:flex-row justify-between md:items-center px-3 md:px-7 bg-red-00 ">
          <p>Username</p>
          <div className=" w-[100%] md:w-[60%] px-8 py-4 md:py-8 bg-white ">
            {user?.username}
          </div>
        </div>
      </div>
      <div className=" mt-10 ">
        <div className="h-[100px] flex flex-col md:flex-row justify-between md:items-center px-3 md:px-7 bg-red-00 ">
          <p>Phone</p>
          <div className=" w-[100%] md:w-[60%] px-8 py-4 md:py-8 bg-white ">
            {user?.phone}
          </div>
        </div>
      </div>
      <div className=" mt-10 ">
        <div className="h-[100px] flex flex-col md:flex-row justify-between md:items-center px-3 md:px-7 bg-red-00 ">
          <p>Crypto</p>
          <div className=" w-[100%] md:w-[60%] px-8 overflow-x-auto py-4 md:py-8 bg-white ">
            {user?.crypto ?? "NULL"}
          </div>
        </div>
      </div>
      <div className=" mt-10 ">
        <div className="h-[100px] flex flex-col md:flex-row justify-between md:items-center px-3 md:px-7 bg-red-00 ">
          <p>Wallet Address</p>
          <div className=" w-[100%] md:w-[60%] px-8 overflow-x-auto py-4 md:py-8 bg-white ">
            {user?.address ?? "NULL"}
          </div>
        </div>
      </div>
      {/* <div className=" mt-6 ">
        <p className=" text-[23px] text-center font-[700] py-5 ">
          Change Account
        </p>
        <div className=" flex justify-center gap-6 items-center bg-red-00 ">
          <p>Weekly Mode</p>
          <SwitchButton
            ref={refd}
            checked={user?.account_mode !== "WEEKLY"}
            onChange={(e: any) => {
              OpenModal2();
            }}
          />
          <p>Business Mode</p>
        </div>
      </div> */}
    </DashboardLayout>
  );
}
