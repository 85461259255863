import { useDispatch } from "react-redux";
import { AppDispatch } from "../store";
import { useNavigate } from "react-router-dom";
import { checkAuth } from "../store/service/auth";
import { routeObj } from "../constants/routes";
import toast from "react-hot-toast";
import { useEffect } from "react";

export const useCheckAuth = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const Authenticated = async () => {
    try {
      const { data } = await dispatch(checkAuth()).unwrap();
      if (!data) {
        dispatch({ type: "auth/logout" });
        navigate(routeObj.login);
        return toast.error("Session Expired");
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    Authenticated();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
