import React from "react";
import LayoutOne from "../../Layout/Layout_One";
import { Button } from "../../components/Button";
import { Link } from "react-router-dom";
import { MdLockPerson, MdPayments } from "react-icons/md";
import { TiGlobe } from "react-icons/ti";
import { routeObj } from "../../constants/routes";
import { BiBriefcaseAlt } from "react-icons/bi";
import { authConfig } from "../../Utils/constants";

export default function About() {
  return (
    <LayoutOne>
      <div
        className=" h-[45vh] bg-center bg-cover md:h-[60vh] text-center bg-primary text-white flex-col flex justify-center items-center "
        style={{ backgroundImage: `url('/images/aboutp.png')` }}
      >
        <h1 className=" w-[90%] md:w-[55%] leading-[40px] md:leading-[70px] px- md:px-16 font-bold text-2xl md:text-5xl ">
          Our vision{" "}
        </h1>
        <p className=" w-[95%] md:w-[50%] px-3 text-sm md:text-lg pt-5 md:pt-8  ">
          “We are committed to assisting individuals and businesses in
          navigating the complexities of the financial world”
        </p>
      </div>
      <div className=" py-7 md:py-20 px-5 md:px-20 ">
        <h2 className="  md:text-2xl font-bold pb-6 ">About us</h2>
        <div className="text-sm leading-[30px] md:text-[16px]">
          {" "}
          As pioneers in the financial landscape, we excel in crafting
          investment strategies that are tailored to your unique needs,
          providing expert advisory services that guide you through complex
          financial decisions, and offering innovative risk management solutions
          that protect your wealth. Our role as fiduciaries drives us to
          safeguard and grow our clients' assets with unwavering commitment,
          employing rigorous due diligence and continuous portfolio monitoring.
          We foster a culture of inspiration among our team, encouraging ongoing
          professional development and collaboration to stay ahead of industry
          trends and develop innovative financial solutions. Beyond our core
          services, we actively contribute to the vitality of local communities
          through financial literacy programs, partnerships with non-profit
          organizations, and promoting sustainable investing practices, all
          while maintaining the highest ethical standards and transparency in
          our operations.
          <div
            style={{ paddingBlock: "10px" }}
            className="flex flex-col md:flex-row md:gap-5 items-center"
          >
            <div className=" md:w-[50%] bg-blac ">
              <img src="/images/logo.png" alt="" />
            </div>
            <div className="">
              {" "}
              <b className=" text-primary uppercase">
                {authConfig.appName}
              </b>{" "}
              with Company Number{" "}
              <Link
                to={
                  "https://find-and-update.company-information.service.gov.uk/company/11609296"
                }
                target="_blank"
              >
                11609296
              </Link>{" "}
              first Incorporated on October 8th, 2018, initially as an
              enterprise risk management and fixed income institutional asset
              manager, is one of the world's largest asset manager, with
              US$8.59 trillion in assets under management as of December 31,
              2022. We operate globally with clients in 100 countries.
            </div>
          </div>
        </div>
      </div>
      <div className=" flex flex-col md:flex-row px-5 md:px-20 bg-blue-00 md:items-center  py-5 md:py-20  ">
        <div className=" md:w-[50%] bg-red-00 md:p-0 ">
          <h1 className=" font-bold text-sm md:text-2xl text-left md:text-left py-5 ">
            Our mission
          </h1>
          <p className=" md:w-[80%] leading-[30px] text-sm md:text-[16px] text-left md:text-left  ">
            We are on a mission to empower individuals and institutions to
            achieve their financial goals through accessible and innovative
            investment solutions. We demystify the financial world, providing
            users with resources, tools, and expert insights for informed
            decision-making.
          </p>
        </div>
        <div className=" mt-10 md:mt-0 ">
          <img src="/images/hgt.png" loading="lazy" alt="" />
        </div>
      </div>
      <div className=" bg-primary py-10   w-full m-auto  ">
        <p className=" pt-5 text-center text-3xl pb-10 text-white font-bold ">
          Why choose us
        </p>
        <div className=" m-auto w-[95%] md:w-[70%] flex-wrap md:flex-row  py-5 flex justify-evenly  gap-4 ">
          <div className=" w-[47%]  md:w-[30%] bg-white text-black shadow-xl rounded-lg px-3 md:px-5  py-10 ">
            <div>
              <img
                className="h-[30px] w-[30px]  "
                src="/images/docs.svg"
                alt=""
              />
            </div>
            <p className=" font-bold text-sm md:text-lg py-4 ">
              Research-Driven Investment <br /> Insights{" "}
            </p>
            <p className=" text-[10px] md:text-sm md:w-[80%] ">
              We conduct rigorous analysis across sectors and asset classes to
              uncover prime investment opportunities for our clients
            </p>
          </div>
          <div className=" w-[47%] md:w-[30%] bg-hexWhite text-black shadow-xl rounded-lg px-2 md:px-5 py-10 ">
            <div>
              <MdPayments className=" text-[30px] " />
              {/* <img
                className="h-[30px] w-[30px]  "
                src="/images/docs.svg"
                alt=""
              /> */}
            </div>
            <p className=" font-bold text-sm md:text-lg py-4 ">
              Payment Options
            </p>
            <p className="  text-[10px] md:text-sm md:w-[80%] ">
              We chose Crypto currency as our first method of payment because of
              its High level of privacy,quick payments and to avoid transaction
              fees.
            </p>
          </div>

          <div className=" w-[47%] md:w-[30%] bg-hexWhite shadow-xl rounded-lg px-3 md:px-5 py-10 ">
            <div>
              {/* <TiGlobe className=" text-[30px] " /> */}
              <img
                className="h-[30px] w-[30px]  "
                src="/images/target.svg"
                alt=""
              />
            </div>
            <p className=" font-bold text-sm md:text-lg py-4 ">
              Tailored Goals, Measured Risks{" "}
            </p>
            <p className=" text-[10px] md:text-sm md:w-[80%] ">
              We start by defining your financial objectives, whether growth,
              income, or preservation, to craft a personalized investment
              strategy.
            </p>
          </div>
          <div className=" w-[47%]  md:w-[30%] bg-hexWhite  shadow-xl rounded-lg px-3 md:px-5 py-10 ">
            <div>
              <MdLockPerson className=" text-[30px] " />
              {/* <img
                className="h-[30px] w-[30px]  "
                src="/images/rare.svg"
                alt=""
              /> */}
            </div>
            <p className=" font-bold text-sm md:text-lg py-4 ">
              Strong Security
            </p>
            <p className=" text-[10px] md:text-sm md:w-[80%] ">
              Investment funds and returns are sent to your own wallet, avoiding
              centralisation risks of conventional exchanges.
            </p>
          </div>
          <div className=" w-[47%]  md:w-[30%] bg-white text- shadow-xl rounded-lg px-3 md:px-5  py-10 ">
            <div>
              <BiBriefcaseAlt className=" text-[30px] " />

              {/* <img
                className="h-[30px] w-[30px]  "
                src="/images/case.svg"
                alt=""
              /> */}
            </div>
            <p className=" font-bold text-sm md:text-lg py-4 ">
              Regular portfolio <br /> Reviews
            </p>
            <p className=" text-[10px] md:text-sm md:w-[80%] ">
              We continually review and adjust your investments to keep pace
              with market shifts and your evolving financial goals
            </p>
          </div>
        </div>
      </div>
      <div className="">
        <p className=" py-10 md:py-20 text-center md:text-3xl   font-bold ">
          Our Team Of Skilled Professionals
        </p>
        <div className=" md:px-20 bg-primar ">
          <div className=" flex flex-col md:flex-row bg-blue-00 items-center  py-0 md:py-10  ">
            <div className=" bg-rgba/85 md:bg-inherit  md:mt-0 w-[90%] px-4 py-2 mx-auto md:w-[50%] flex md:flex-col items-center justify-left gap-2 md:gap-3 ">
              <div
                className=" w-[100px] md:w-[200px] h-[100px] md:h-[200px] bg-black rounded-full bg-center bg-cover bg-no-repeat  "
                style={{ backgroundImage: `url('/images/allen.jpeg')` }}
              ></div>
              <div className=" text-center w-full text-white md:text-black ">
                <p className=" font-bold ">Allen C. Odia</p>
                <p className=" font-bold ">Chief Executive Offiver</p>
              </div>
            </div>
            <div className=" md:w-[50%] bg-red-00 p-5 md:p-0 ">
              <p className="  font-thin text-[12px] md:text-[16px] text-center md:text-left  ">
                Allen C. Odia Allen is the founder and CEO of{" "}
                {authConfig.appName}. With over two decades of experience in
                global financial markets, Allen brings a wealth of knowledge to
                the firm. He previously served as a senior portfolio manager at
                BlackRock, overseeing multi-billion dollar funds focused on
                emerging markets. Prior to that, he held key positions at
                Goldman Sachs and Morgan Stanley in New York and London,
                specializing in quantitative trading strategies. Allen holds a
                Bachelor's degree in Economics from Harvard University and an
                MBA from Stanford Graduate School of Business, where he was a
                Siebel Scholar. He is a CFA Charterholder and has been
                recognized as a Young Global Leader by the World Economic Forum.
              </p>
            </div>
          </div>

          <div className=" flex flex-col md:flex-row bg-blue-00 items-center  py-0 md:py-10  ">
            <div className=" bg-rgba/85 md:bg-inherit  md:mt-0 w-[90%] px-4 py-2 mx-auto md:w-[50%] flex md:flex-col items-center justify-left gap-2 md:gap-3 ">
              <div
                className=" w-[100px] md:w-[200px] h-[100px] md:h-[200px] bg-black rounded-full bg-center bg-cover bg-no-repeat  "
                style={{ backgroundImage: `url('/images/mary.jpeg')` }}
              ></div>
              <div className=" text-center w-full text-white md:text-black ">
                <p className=" font-bold ">Mary A. Geta</p>
                <p className=" font-bold ">Co-founder</p>
              </div>
            </div>
            <div className=" md:w-[50%] bg-red-00 p-5 md:p-0 ">
              <p className="  font-thin text-[12px] md:text-[16px] text-center md:text-left  ">
                Mary A. Geta, CFA, CFP, is a co-founder of {authConfig.appName}{" "}
                and leads the firm's strategic planning and client relations
                initiatives. With over 15 years of experience in wealth
                management and financial planning, Mary brings a client-centric
                approach to the company's operations. Her expertise lies in
                developing comprehensive financial strategies for high-net-worth
                individuals and institutional clients. Prior to co-founding
                Access Wealth, Mary served as a Senior Vice President at Merrill
                Lynch, where she managed a portfolio of over $1 billion in
                assets. She began her career at Vanguard, quickly rising through
                the ranks to become a key advisor in their Personal Investor
                Group. Mary holds a Bachelor's degree in Finance from the
                University of Pennsylvania's Wharton School and an MBA from
                INSEAD.
              </p>
            </div>
          </div>

          <div className=" flex flex-col md:flex-row bg-blue-00 items-center  py-0 md:py-10  ">
            <div className=" bg-rgba/85 md:bg-inherit  md:mt-0 w-[90%] px-4 py-2 mx-auto md:w-[50%] flex md:flex-col items-center justify-left gap-2 md:gap-3 ">
              <div
                className=" w-[100px] md:w-[200px] h-[100px] md:h-[200px] bg-black rounded-full bg-center bg-cover bg-no-repeat  "
                style={{ backgroundImage: `url('/images/yhang.jpeg')` }}
              ></div>
              <div className=" text-center w-full text-white md:text-black ">
                <p className=" font-bold ">Robert M. Tim </p>
                <p className=" font-bold text-sm ">Senior Managing Director </p>
              </div>
            </div>
            <div className=" md:w-[50%] bg-red-00 p-5 md:p-0 ">
              <p className="  font-thin text-[12px] md:text-[16px] text-center md:text-left  ">
                Robert joined {authConfig.appName} in 2020 as the Senior
                Managing Director. With a strong background in venture capital
                and financial analysis, Robert specializes in identifying
                high-potential startups and providing strategic investment
                advice to both individual and institutional clients. His
                expertise spans across technology, fintech, and sustainable
                energy sectors. Before joining Access Wealth, Robert honed his
                skills at SoftBank Vision Fund and Goldman Sachs' Investment
                Banking Division. He holds a Master's degree in Financial
                Engineering from UC Berkeley and a Bachelor's degree in Computer
                Science from Tsinghua University. Robert is a CFA charterholder
                and a member of the Global Association of Risk Professionals.
                His analytical acumen and deep understanding of emerging
                technologies have been instrumental in driving Access Venture's
                impressive portfolio performance
              </p>
            </div>
          </div>

          <div className=" flex flex-col md:flex-row bg-blue-00 items-center  py-0 md:py-10  ">
            <div className=" bg-rgba/85 md:bg-inherit  md:mt-0 w-[90%] px-4 py-2 mx-auto md:w-[50%] flex md:flex-col items-center justify-left gap-2 md:gap-3 ">
              <div
                className=" w-[100px] md:w-[200px] h-[100px] md:h-[200px] bg-black rounded-full bg-center bg-cover bg-no-repeat  "
                style={{ backgroundImage: `url('/images/david.jpeg')` }}
              ></div>
              <div className=" text-center w-full text-white md:text-black ">
                <p className=" font-bold ">Anderson G. Smith</p>
                Co-lead Access Venture and Analyst{" "}
              </div>
            </div>
            <div className=" md:w-[50%] bg-red-00 p-5 md:p-0 ">
              <p className="  font-thin text-[12px] md:text-[16px] text-center md:text-left  ">
                Anderson G. Smith is the Co-lead of Access Venture and Analyst
                of {authConfig.appName}, where he leads the firm's Global
                Investment Strategy Group. Anderson pioneered the Access Elite
                Program, which successfully guided 45 high-net-worth clients to
                collectively accumulate over $100 million in wealth during 2023.
                Prior to his current position, Anderson served as the Head of
                Global Asset Allocation and Risk Management at Access
                Investments. His illustrious career includes previous roles as
                Chief Investment Officer at Morgan Stanley Investment Management
                and Global Head of Research at Goldman Sachs Asset Management.
                Anderson has extensive international experience, having spent
                five years in Hong Kong as the Analyst of {authConfig.appName}{" "}
                Asia-Pacific operations, where he significantly expanded the
                firm's presence in emerging markets. He holds an MBA from
                Harvard Business School and a Bachelor's degree in Economics
                from Princeton University.
              </p>
            </div>
          </div>
        </div>
        <div
          className=" bg-center  md:mb-40 bg-cover  h-[200px] w-full md:h-[600px] flex-col bg-primary  bg-no-repeat "
          style={{ backgroundImage: `url('/images/shake.png')` }}
        >
          <div className=" h-full w-full bg-rgba flex items-center justify-center  ">
            <div className=" w-[80%] bg-red-00 ">
              <p className=" md:text-5xl text-center pb-10 px-6 md:px-20 md:leading-[65px] font-bold text-white ">
                Thank you for partnering with {authConfig.appName}
              </p>
              <Link to={routeObj.register}>
                <Button className=" m-auto block font-bold bg-white px-7 py-2 text-primary ">
                  Join Us
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </LayoutOne>
  );
}
