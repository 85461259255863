import classNames from "classnames";
import { PulseLoader } from "react-spinners";
import { motion } from "framer-motion";

type Props = {
  type?: "submit" | "button";
  className?: string;
  loading?: boolean;
  id?: string;
  onClick?: (e?: any) => void;
  children: React.ReactNode;
  disabled?: boolean;
};

export const Button = ({
  children,
  type = "button",
  className,
  id,
  loading,
  onClick,
  disabled,
}: Props) => {
  return (
    <motion.button
      whileHover={{ scale: 1.07 }}
      whileTap={{ scale: 1.03 }}
      type={type}
      className={classNames(
        ` ${
          loading || disabled ? "bg-disabled cursor-not-allowed " : ""
        } my- text-center  rounded-lg ${className} `
      )}
      id={id}
      onClick={onClick}
      disabled={loading || disabled}
    >
      {loading ? <PulseLoader loading={true} color="white" /> : children}
    </motion.button>
  );
};
